import api from '../../rest/api';

const initialState = {
};

export default {
  state: { ...initialState },
  actions: {
    async billingPay( _, { accessToken, uid, name, contact, birth, card, expiryDate, pw }) {
      try {
        const response = await api.billingPay(accessToken, uid, name, contact, birth, card, expiryDate, pw);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
  }
};