<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1280px;">

      <navigation-view
        :profile-image="profile.profileImage"
        @my-btn-clicked="myBtnCkd"
      />

        <v-container class="my-3 pa-0 d-flex align-center" fluid>
          <v-btn 
            @click="backBtnCkd" 
            text
            color="black"
          >
            <v-icon class="back-icon">mdi-chevron-left</v-icon>
            <span>{{ $t('myappTitle') }}</span>
          </v-btn>
        </v-container>

        <v-container class="mb-10" fluid>
          <v-row>
            <v-col
              class="mt-3"
              v-for="(app, index) in apps.my"
              :key="`apps-my-${index}`" 
              cols="12"
              @click="appDetailBtnCkd(app.id)"
              style="cursor: pointer;"
            >
              <v-card flat elevation="0">
                <v-row align="center" no-gutters>

                  <v-col cols="auto" class="mr-3">
                    <v-img 
                      class="app-icon app-icon-elevation"
                      :src="app.icon"
                    />
                  </v-col>
                  
                  <v-col class="pa-0 text-left" style="min-width: 0;">
                    <div class="d-flex flex-column">
                      <div class="text-subtitle-1 font-weight-medium font-weight-bold text-truncate">{{ app.name }}</div>
                      <div class="text-body-2 grey--text text-truncate">{{ app.subname }}</div>
                    </div>
                  </v-col>

                  <v-col cols="auto" class="d-flex align-center ml-auto">
                    <p :class="getStatusClass(app.status)" class="mb-0 mr-2 text-nowrap">
                      <span class="status-dot mr-1">⦁</span>
                      {{ getStatusText(app.status) }}
                    </p>
                    <v-menu
                      bottom
                      left
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          @click.stop="appModifyBtnCkd(app.id)"
                        >
                          <v-list-item-title>
                            {{ $t('appModify') }}
                          </v-list-item-title>
                        </v-list-item>
                        <!-- <v-list-item
                          @click.stop="appDeleteBtnCkd(app.id)"
                        >
                          <v-list-item-title>
                            {{ $t('appDelete') }}
                          </v-list-item-title>
                        </v-list-item> -->
                      </v-list>
                    </v-menu>
                  </v-col>

                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationView from './elements/NavigationView.vue'
export default {
  name: 'MyAppView',
  data() {
    return {
      profile: {
        name: '',
        profileImage: require('@/assets/ic_tab_profile.svg'),
      },
      apps: {
        my: [],
      }
    };
  },
  components: {
    NavigationView,
  },
  methods: {
    appsMy() {
      this.$store.dispatch('appsMy', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
      })
      .then((result) => {
        if (result.success) {
          this.apps.my = this.$store.getters.getAppsMy;
        }
        else {
          console.log("appsMy error occured.")
        }
      });
    },
    appModifyBtnCkd(aid) {
      this.$router.push({ path: '/modify-app', query: { id: aid } });
    },
    // appDeleteBtnCkd(aid) {
    //   this.$router.push({ path: '/modify-app', query: { id: aid } });
    // },
    appDetailBtnCkd(aid) {
      this.$router.push({ path: '/app-detail', query: { id: aid } });
    },
    myBtnCkd() {
      if(this.$store.getters.isLoggedIn) {
        this.$router.push('/me');
      }
      else {
        this.$router.push('/login');
      }
    },
    backBtnCkd() {
      this.$router.go(-1);
    },
    getStatusText(status) {
      switch(status) {
        case 'approved':
          return this.$t('appApproved');
        case 'pending':
          return this.$t('appPending');
        case 'denied':
          return this.$t('appDenied');
        default:
          return this.$t('appPending');
      }
    },
    getStatusClass(status) {
      switch(status) {
        case 'approved':
          return 'status-text status-approved';
        case 'pending':
          return 'status-text status-pending';
        case 'denied':
          return 'status-text status-denied';
        default:
          return 'status-text status-pending';
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if(!(this.$store.getters.isLoggedIn)) {
      this.$router.push('/');
      return;
    }
    this.profile.name = this.$store.getters.getName;
    this.profile.profileImage = this.$store.getters.getProfileImage;
    this.appsMy();
  },
};
</script>

<style scoped>

/* icon */

.status-text {
  display: flex;
  align-items: center;
}
.status-dot {
  font-size: 20px;
}
.status-approved {
  color: green;
}
.status-pending {
  color: orange;
}
.status-denied {
  color: var(--red);
}

</style>