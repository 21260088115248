import axios from 'axios';

const TOKEN_REFRESH = "https://aeida.io/internal/api/token/refresh.php"

const USER_REGISTER = "https://aeida.io/internal/api/users/register";
const USER_REGISTER_DEVELOPER = "https://aeida.io/internal/api/users/register_developer";
const USER_LOGIN = "https://aeida.io/internal/api/users/login";
const USER_ME = "https://aeida.io/internal/api/users/me";
const USER_UPDATE = "https://aeida.io/internal/api/users/update";
const USER_DELETE = "https://aeida.io/internal/api/de/users";

const APPS_ADD = "https://aeida.io/internal/api/apps/add";
const APPS_MODIFY = "https://aeida.io/internal/api/apps/modify";
const APPS_RECOMMENDS = "https://aeida.io/internal/api/apps/recommends";
const APPS_ALL = "https://aeida.io/internal/api/apps/all";
const APPS_SEARCHES = "https://aeida.io/internal/api/apps/searches";
const APPS_MY = "https://aeida.io/internal/api/apps/my";
const APPS_DETAIL = "https://aeida.io/internal/api/apps/detail";
const APPS_UPDATE_VIEWS = "https://aeida.io/internal/api/apps/update_views";
const APPS_UPDATE_INTERACTIONS = "https://aeida.io/internal/api/apps/update_interactions";
const APPS_REPORT = "https://aeida.io/internal/api/apps/report";

const LIBRARY_GET = "https://aeida.io/internal/api/library/get";
const LIBRARY_ADD = "https://aeida.io/internal/api/library/add";
const LIBRARY_REMOVE = "https://aeida.io/internal/api/library/remove";

const AUTHENTICATE_CHECK_TXT = "https://aeida.io/internal/api/authenticate/check_txt";
const AUTHENTICATE_TRANSFER = "https://aeida.io/internal/api/authenticate/transfer";

const BILLING_PAY = "https://aeida.io/internal/api/billing/pay";

const INQUIRY_WRITE = "https://aeida.io/internal/api/inquiry/write.php";

export default {
  tokenRefresh(refreshToken, uid) {
    const formData = new FormData();
    formData.append('uid', uid);
    return axios.post(TOKEN_REFRESH, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${refreshToken}`,
      }
    })
  },
  userRegister(email, pw, name) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('pw', pw);
    formData.append('name', name);
    return axios.post(USER_REGISTER, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  userRegisterDeveloper(accessToken, uid) {
    const formData = new FormData();
    formData.append('uid', uid);
    return axios.post(USER_REGISTER_DEVELOPER, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  userLogin(email, pw) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('pw', pw);
    return axios.post(USER_LOGIN, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  userMe(accessToken, uid) {
    const formData = new FormData();
    formData.append('uid', uid);
    return axios.post(USER_ME, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  userUpdate(uid, fcmToken) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('fcm_token', fcmToken);
    return axios.post(USER_UPDATE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  userDelete(uid) {
    const formData = new FormData();
    formData.append('uid', uid);
    return axios.post(USER_DELETE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  appsAdd(accessToken, uid, url, icon, name, subname, description, screenshots, category, language, keywords) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('url', url);
    formData.append('icon', icon);
    formData.append('name', name);
    formData.append('subname', subname);
    formData.append('description', description);
    for (let i = 0; i < screenshots.length; i++) {
        formData.append('screenshots[]', screenshots[i]);
    }
    formData.append('category', category);
    formData.append('language', language);
    formData.append('keywords', keywords);
    return axios.post(APPS_ADD, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
      }
    })
  },
  appsModify(accessToken, uid, aid, url, icon, name, subname, description, screenshots, category, language, keywords) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('aid', aid);
    formData.append('url', url);
    formData.append('icon', icon);
    formData.append('name', name);
    formData.append('subname', subname);
    formData.append('description', description);
    for (let i = 0; i < screenshots.length; i++) {
        formData.append('screenshots[]', screenshots[i]);
    }
    formData.append('category', category);
    formData.append('language', language);
    formData.append('keywords', keywords);
    return axios.post(APPS_MODIFY, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
      }
    })
  },
  appsRecommends() {
    return axios.post(APPS_RECOMMENDS)
  },
  appsAll(offset, loadCount) {
    const formData = new FormData();
    formData.append('offset', offset);
    formData.append('load_count', loadCount);
    return axios.post(APPS_ALL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
  },
  appsSearches(keyword) {
    const formData = new FormData();
    formData.append('keyword', keyword);
    return axios.post(APPS_SEARCHES, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  appsMy(accessToken, uid) {
    const formData = new FormData();
    formData.append('uid', uid);
    return axios.post(APPS_MY, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
      }
    })
  },
  appsDetail(aid) {
    const formData = new FormData();
    formData.append('aid', aid);
    return axios.post(APPS_DETAIL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  appsUpdateViews(aid) {
    const formData = new FormData();
    formData.append('aid', aid);
    return axios.post(APPS_UPDATE_VIEWS, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  appsUpdateInteractions(aid) {
    const formData = new FormData();
    formData.append('aid', aid);
    return axios.post(APPS_UPDATE_INTERACTIONS, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  appsReport(accessToken, uid, aid, reason) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('aid', aid);
    formData.append('reason', reason);
    return axios.post(APPS_REPORT, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
      }
    })
  },
  libraryGet(accessToken, uid) {
    const formData = new FormData();
    formData.append('uid', uid);
    return axios.post(LIBRARY_GET, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
      }
    })
  },
  libraryAdd(accessToken, uid, aid) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('aid', aid);
    return axios.post(LIBRARY_ADD, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
      }
    })
  },
  libraryRemove(isLoggedIn, accessToken, uid, aid) {
    const formData = new FormData();
    formData.append('is_logged_in', isLoggedIn);
    formData.append('uid', uid);
    formData.append('aid', aid);
    return axios.post(LIBRARY_REMOVE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
      }
    })
  },
  authenticateChcekTxt(url, uniqueToken) {
    const formData = new FormData();
    formData.append('url', url);
    formData.append('unique_token', uniqueToken);
    return axios.post(AUTHENTICATE_CHECK_TXT, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
  },
  authenticateTransfer(accessToken, uid, aid) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('aid', aid);
    return axios.post(AUTHENTICATE_TRANSFER, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
      }
    })
  },
  inquiryWrite(accessToken, uid, email, contents) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('email', email);
    formData.append('contents', contents);
    return axios.post(INQUIRY_WRITE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  billingPay(accessToken, uid, name, contact, birth, card, expiryDate, pw) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('name', name);
    formData.append('contact', contact);
    formData.append('birth', birth);
    formData.append('card', card);
    formData.append('expiryDate', expiryDate);
    formData.append('pw', pw);
    return axios.post(BILLING_PAY, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
      }
    })
  },
};