<template>
  <v-app>
    <v-app-bar 
      @click="backBtnCkd"
      color="var(--theme)" 
      app 
      height="20" 
      class="pa-0 ma-0" 
      flat
      elevation="0"
    >
      <v-toolbar-title 
        class="font-weight-bold white--text" 
        style="font-size: 10px; 
        position: absolute; 
        left: 50%; 
        transform: translateX(-50%);"
      >
        {{$t('hostedTitle')}}
      </v-toolbar-title>
    </v-app-bar>
    <v-main class="d-flex justify-center full-size overflow-hidden">
      <iframe :src="url" width="100%" height="100%" frameborder="0"></iframe>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'HostedView',
  data() {
    return {
      url: '',
    }
  },
  methods: {
    setGlobalStyle() {
      document.body.style.backgroundColor = 'var(--theme)';
      document.documentElement.style.backgroundColor = 'var(--theme)';
      document.body.style.overflow = 'hidden'; // Prevents body scrolling
    },
    removeGlobalStyle() {
      document.body.style.backgroundColor = '';
      document.documentElement.style.backgroundColor = '';
      document.body.style.overflow = ''; // Restores body scroll
    },
    backBtnCkd() {
      this.$router.push('/');
    }
  },
  mounted() {
    this.setGlobalStyle();
    window.scrollTo(0, 0);
    this.url = this.$store.getters.getHostedUrl;
  },
  beforeDestroy() {
    this.removeGlobalStyle();
  }
}
</script>

<style scoped>
.v-main {
  overflow: hidden; /* Prevents scrolling within the main content */
}
</style>