<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1280px;">

      <navigation-view
        :profile-image="profile.profileImage"
        @my-btn-clicked="myBtnCkd"
      />

        <v-container class="my-3 pa-0 d-flex align-center" fluid>
          <v-btn 
            @click="backBtnCkd" 
            text
            color="black"
          >
            <v-icon class="back-icon">mdi-chevron-left</v-icon>
            <span>{{ $t('appAuthenticateTitle') }}</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="var(--theme)"
            rounded 
            v-on:click="completeBtnCkd"
            class="px-5 mr-3 white--text font-weight-bold"
            v-if="authenticate.showCompleteBtn"
          >
            {{ $t('appAuthenticateComplete') }}
          </v-btn>
        </v-container>

        <v-container fluid>

          <v-card flat elevation="0">
            <v-row align="center" no-gutters>

              <v-col cols="auto" class="mr-3">
                <v-img 
                  :src="app.detail.icon" 
                  height="70px" 
                  width="70px"
                  :style="{ borderRadius: '16px' }"
                />
              </v-col>
              
              <v-col class="pa-0 text-left">
                <div>
                  <div class="text-h5 font-weight-medium font-weight-bold">{{ app.detail.name }}</div>
                  <div class="text-subtitle-1 grey--text">{{ app.detail.subname }}</div>
                </div>
              </v-col>

            </v-row>
          </v-card>

          <p class="text-body-2 mt-6 black--text">1. 해당 앱의 루트 경로에 aeida.txt 파일을 추가하세요. 예를 들어 aeida.io/aeida.txt 와 같습니다. <br><br> 2. 추가한 파일에 <b style="padding: 2px;background-color:var(--theme);color:white;">{{ uniqueToken }}</b> 를 입력 후 저장하세요. <br><br> 3. 외부에서 aeida.txt 파일에 접근 가능한지 확인하세요.</p>

          <v-col class="pa-0 mt-8" v-if="authenticate.showLoading">
            <v-row no-gutters align="center" class="full-width">
              <v-col cols="auto" class="mr-2">
                <v-img
                  :src="require('@/assets/ic_rolling.gif')"
                  width="20"
                  height="20"
                  contain
                />
              </v-col>
              <v-col>
                <p class="text-body-2 black--text mb-0">
                  {{ authenticate.loadingText }}
                </p>
              </v-col>
            </v-row>
          </v-col>

        </v-container>

        <v-snackbar style="margin-bottom: 60px;" v-model="snackbar.visibility">
          <v-container class="text-center pd-0" style="padding: 0;">
            {{ snackbar.text }}
          </v-container>
          <template v-slot:actions>
            </template>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationView from './elements/NavigationView.vue'
export default {
  name: 'AppAunticateView',
  data() {
    return {
      uniqueToken: '',
      authenticate: {
        loadingText: '',
        showCompleteBtn: true,
        showLoading: false,
      },
      profile: {
        name: '',
        profileImage: require('@/assets/ic_tab_profile.svg'),
      },
      app: {
        aid: null,
        detail: [],
      },
      snackbar: {
        text: "",
        visibility: false,
      }
    };
  },
  components: {
    NavigationView
  },
  methods: {
    appsDetail() {
      this.$store.dispatch('appsDetail', {
        aid: this.app.aid
      })
      .then((result) => {
        if (result.success) {
          this.app.detail = this.$store.getters.getAppsDetail;
        }
        else {
          console.log("appsDetail error occured.")
        }
      });
    },
    transferApp() {
      setTimeout(() => {
        this.$store.dispatch('authenticateTransfer', {
          accessToken: this.$store.getters.getAccessToken,
          uid: this.$store.getters.getUID,
          aid: this.app.aid
        })
        .then(result => {
          if (result.success) {
            this.authenticate.loadingText = this.$t('appAuthenticateTextTransferComplete');
            this.authenticate.showCompleteBtn = false;
          } else {
            this.snackbar.text = this.$t('serverError');
            this.snackbar.visibility = true;
          }
        })
      }, 1500);
    },
    completeBtnCkd() {
      var authenticate_url = this.app.detail.url+"/aeida.txt";
      this.authenticate.loadingText = this.$t('appAuthenticateTextCheck');
      this.authenticate.showLoading = true;
      setTimeout(() => {
        this.$store.dispatch('authenticateCheckTxt', {
          url: authenticate_url,
          uniqueToken: this.uniqueToken
        })
        .then(result => {
          if (result.success) {
            this.authenticate.loadingText = this.$t('appAuthenticateTextTransfer');
            this.transferApp();
          } else {
            this.authenticate.loadingText = this.$t('appAuthenticateTextCheckFailed');
          }
        })
      }, 1500);
    },
    myBtnCkd() {
      if(this.$store.getters.isLoggedIn) {
        this.$router.push('/me');
      }
      else {
        this.$router.push('/login');
      }
    },
    backBtnCkd() {
      this.$router.go(-1);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if(!(this.$store.getters.isLoggedIn)) {
      this.$router.push('/');
    }
    if(this.$route.query.id) {
      this.app.aid = this.$route.query.id;
    }
    else {
      this.backBtnCkd();
    }
    this.profile.profileImage = this.$store.getters.getProfileImage;
    this.appsDetail();
    this.uniqueToken = this.$store.getters.getUniqueToken;
  },
};
</script>

<style scoped>

</style>