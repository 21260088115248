<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1280px;">

        <navigation-view
          :profile-image="profile.profileImage"
          @my-btn-clicked="myBtnCkd"
        />

        <v-container fluid>
          <v-row>

            <v-col cols="12">
              <v-btn
                class="w-100 pa-7"
                :style="{ backgroundColor: 'var(--gray)', justifyContent: 'start' }"
                elevation="0"
                rounded
                block
                to="/account"
              >
                {{ $t('meAccount') }}
              </v-btn>
            </v-col>

            <v-col cols="12" v-if="isDeveloper">
              <v-btn
                class="w-100 pa-7"
                :style="{ backgroundColor: 'var(--gray)', justifyContent: 'start' }"
                elevation="0"
                rounded
                block
                to="/developer"
              >
                {{ $t('meDeveloper') }}
              </v-btn>
            </v-col>

            <v-col cols="12" v-if="!isDeveloper">
              <v-btn
                class="w-100 pa-7"
                :style="{ backgroundColor: 'var(--gray)', justifyContent: 'start' }"
                elevation="0"
                rounded
                block
                to="/developer-register"
              >
                {{ $t('meDeveloperRegister') }}
              </v-btn>
            </v-col>

            <v-col cols="12">
              <v-btn
                class="w-100 pa-7"
                :style="{ backgroundColor: 'var(--gray)', justifyContent: 'start' }"
                elevation="0"
                rounded
                block
                to="/inquiry"
              >
                {{ $t('meInquiry') }}
              </v-btn>
            </v-col>

            <v-col cols="12" v-if="!isPWA">
              <v-btn
                class="w-100 pa-7"
                :style="{ backgroundColor: 'var(--gray)', justifyContent: 'start' }"
                elevation="0"
                rounded
                block
                to="/pwa"
              >
                {{ $t('pwaGuideTitle') }}
              </v-btn>
            </v-col>

          </v-row>
        </v-container>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import functions from '@/rest/functions';
import NavigationView from './elements/NavigationView.vue'
export default {
  name: 'MeView',
  data() {
    return {
      profile: {
        profileImage: require('@/assets/ic_tab_profile.svg'),
      },
      isPWA: false,
    };
  },
  components: {
    NavigationView,
  },
  computed: {
    isDeveloper() {
      return this.$store.getters.getDeveloper === 'true';
    }
  },
  methods: {
    myBtnCkd() {
      // no-use
    },
    backBtnCkd() {
      this.$router.go(-1);
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    if(!(this.$store.getters.isLoggedIn)) {
      this.$router.push('/');
    }
    this.profile.profileImage = this.$store.getters.getProfileImage;
    this.isPWA = functions.isPWA();
  }
};
</script>

<style scoped>

</style>