const initialState = {
  url: null,
};

export default {
  state: { ...initialState },
  mutations: {
    setHostedUrl(state, url) {
      state.url = url;
    },
  },
  getters: {
    getHostedUrl(state) {
      return state.url;
    },
  },
};