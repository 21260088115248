<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1280px;">

      <navigation-view
        :profile-image="profile.profileImage"
        @my-btn-clicked="myBtnCkd"
      />

        <v-container class="my-3 pa-0" fluid>
          <v-btn 
            @click="backBtnCkd" 
            text
            color="black"
          >
            <v-icon class="back-icon">mdi-chevron-left</v-icon>
            <span> {{ $t('developerTitle') }} </span>
          </v-btn>
        </v-container>

        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-btn
                class="w-100 pa-7"
                :style="{ backgroundColor: 'var(--gray)', justifyContent: 'start' }"
                elevation="0"
                rounded
                block
                to="/new-app"
              >
                {{ $t('developerNewApp') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                class="w-100 pa-7"
                :style="{ backgroundColor: 'var(--gray)', justifyContent: 'start' }"
                elevation="0"
                rounded
                block
                to="/my-app"
              >
                {{ $t('developerMyApp') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationView from './elements/NavigationView.vue'
export default {
  name: 'DeveloperView',
  data() {
    return {
      profile: {
        profileImage: require('@/assets/ic_tab_profile.svg'),
      },
    };
  },
  components: {
    NavigationView,
  },
  methods: {
    myBtnCkd() {
      if(this.$store.getters.isLoggedIn) {
        this.$router.push('/me');
      }
      else {
        this.$router.push('/login');
      }
    },
    backBtnCkd() {
      this.$router.go(-1);
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    if(!(this.$store.getters.isLoggedIn)) {
      this.$router.push('/');
      return;
    }
    this.profile.profileImage = this.$store.getters.getProfileImage;
  }
};
</script>

<style scoped>

</style>