<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1280px;">

      <navigation-view
        :profile-image="profile.profileImage"
        @my-btn-clicked="myBtnCkd"
      />

        <v-container class="my-3 pa-0 d-flex align-center" fluid>
          <v-btn 
            @click="backBtnCkd" 
            text
            color="black"
          >
            <v-icon class="back-icon">mdi-chevron-left</v-icon>
            <span>{{ $t('modifyAppTitle') }}</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="var(--theme)"
            rounded 
            v-on:click="completeBtnCkd"
            class="px-5 mr-3 white--text font-weight-bold"
          >
            {{ $t('modifyAppComplete') }}
          </v-btn>
        </v-container>

        <v-container fluid>
          <v-btn 
            class="pa-5 d-flex flex-column align-center" 
            style="background-color: #F5F5FF; width: 100%;" 
            elevation="0"
            href="/app-guidelines"
            target="_blank"
          >
            <span class="mr-2">{{ $t('modifyAppGuidelines') }}</span>
            <v-icon small>mdi-open-in-new</v-icon>
          </v-btn>
        </v-container>

        <v-container fluid>

          <p>{{ $t('modifyAppUrl') }}</p>

          <v-col class="pa-0 mb-6">
            <v-text-field
              v-model="app.url"
              readonly
              placeholder="https://" 
              hide-details
              style="background-color: var(--gray);"
              rounded 
              class="ipt mb-5 py-2"
            />
            <div>
            </div>
              <p class="black--text text-right mb-0">
                {{ $t('modifyAppProvider') }} <b>{{ profile.name }}</b>
              </p>
          </v-col>
          
          <p>{{ $t('modifyAppIcon') }}</p>
          <p class="text-body-2 dark-gray--text">◦ 512x512px 권장</p>

          <v-col class="pa-0 mb-6">
            <div class="app-icon-elevation">
              <v-btn
                @click="selectIconBtnCkd"
                :style="{ borderRadius: '16px' }"
                class="pa-0 transparent-btn"
                min-width="100px"
                min-height="100px"
              >
                <v-img 
                  :src="app.iconPreview"
                  height="100px" 
                  width="100px"
                  :style="{ borderRadius: '16px' }"
                />
              </v-btn>
            </div>
            <input
              type="file"
              ref="imageInput"
              @change="onIconSelected"
              accept="image/*"
              style="display: none;"
            />
          </v-col>

          <p>{{ $t('modifyAppName') }}</p>
          <p class="text-body-2 dark-gray--text">{{ $t('modifyAppNameSubtitle') }}</p>

          <v-col class="pa-0 mb-6">
            <div>
              <v-text-field
                v-model="app.name"
                hide-details
                style="background-color: var(--gray);"
                rounded 
                class="ipt mb-5 py-2"/>
            </div>
          </v-col>

          <p>{{ $t('modifyAppSubname') }}</p>
          <p class="text-body-2 dark-gray--text">{{ $t('modifyAppSubnameSubtitle') }}</p>

          <v-col class="pa-0 mb-6">
            <div>
              <v-text-field
                v-model="app.subname"
                hide-details
                style="background-color: var(--gray);"
                rounded 
                class="ipt mb-5 py-2"/>
            </div>
          </v-col>

          <p>{{ $t('modifyAppDescription') }}</p>
          <p class="text-body-2 dark-gray--text">{{ $t('modifyAppDescriptionSubtitle') }}</p>

          <v-textarea
            v-model="app.description"
            :placeholder="$t('modifyAppDescriptionPlaceholder')"
            flat
            solo
            full-width
            height="300"
            hide-details
            class="app-description-textarea mb-8"
            style="border-radius: 16px;"
            background-color="var(--gray)"
          ></v-textarea>

          <p>{{ $t('modifyAppScreenshot') }}</p>
          <p class="text-body-2 dark-gray--text">{{ $t('modifyAppScreenshotSubtitle') }}</p>

          <v-container fluid class="ma-0 pa-0 scroll-container mb-6">
            <v-row no-gutters class="overflow-x-auto flex-nowrap">
              <v-col v-for="(screenshot, index) in displayScreenshots" :key="index" cols="auto" class="mr-2 screenshot-container">
                <v-img
                  :src="screenshot || require('@/assets/ic_screenshot_add.svg')"
                  :aspect-ratio="9/18"
                  width="200"
                  cover
                  style="cursor: pointer;"
                  :style="{
                    borderRadius: '16px',
                    overflow: 'hidden',
                  }"
                  @click="selectScreenshotsBtnCkd"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-btn
                  v-if="screenshot"
                  icon
                  small
                  color="var(--red)"
                  class="delete-btn"
                  @click.stop="deleteScreenshot(index)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

          <p>{{ $t('modifyAppCategory') }}</p>

          <v-select
            class="py-2 mb-6"
            v-model="app.category"
            :items="categories"
            item-text="text"
            item-value="value"
            hide-details
            style="background-color: var(--gray);"
            required
            rounded
          ></v-select>

          <p>{{ $t('modifyAppLanguage') }}</p>

          <v-select
            class="py-2 mb-6"
            v-model="app.language"
            :items="languages"
            item-text="text"
            item-value="value"
            hide-details
            style="background-color: var(--gray);"
            required
            rounded
          ></v-select>

          <p>{{ $t('modifyAppKeyword') }}</p>
          <p class="text-body-2 dark-gray--text">{{ $t('modifyAppKeywordSubtitle') }}</p>

          <v-col class="pa-0 mb-6">
            <div>
              <v-text-field
                v-model="app.keywords" 
                hide-details
                style="background-color: var(--gray);"
                rounded 
                class="ipt mb-10 py-2"/>
            </div>
          </v-col>

        </v-container>

        <v-snackbar style="margin-bottom: 60px;" v-model="snackbar.visibility">
          <v-container class="text-center pd-0" style="padding: 0;">
            {{ snackbar.text }}
          </v-container>
          <template v-slot:actions>
            </template>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationView from './elements/NavigationView.vue'
import functions from '@/rest/functions';
export default {
  name: 'ModifyAppView',
  data() {
    return {
      completeTrigger: false,
      showLoading: false,
      isIconSelected: false,
      profile: {
        name: '',
        profileImage: require('@/assets/ic_tab_profile.svg'),
      },
      app: {
        preload: [],
        id: '',
        url: '',
        icon: null,
        iconPreview: require('@/assets/ic_item_add.svg'),
        name: '',
        subname: '',
        description: '',
        screenshots: [],
        screenshotPreviews: [],
        category: '',
        language: '',
        keywords: '',
      },
      categories: [
        { text: this.$t('categoryEmpty'), value: '' },
        { text: this.$t('categoryDating'), value: 'dating' },
        { text: this.$t('categoryGame'), value: 'game' },
        { text: this.$t('categoryEntertainment'), value: 'entertainment' },
        { text: this.$t('categoryShopping'), value: 'shopping' },
        { text: this.$t('categoryTravel'), value: 'travel' },
        { text: this.$t('categoryEducation'), value: 'education' },
        { text: this.$t('categoryMedia'), value: 'media' },
        { text: this.$t('categorySocial'), value: 'social' },
        { text: this.$t('categoryFinancial'), value: 'financial' },
      ],
      languages: [
        { text: this.$t('languageEmpty'), value: '' },
        { text: this.$t('languageKorean'), value: 'KR' },
        { text: this.$t('languageEnglish'), value: 'EN' },
        { text: this.$t('languageJapanese'), value: 'JP' },
        { text: this.$t('languageGlobal'), value: 'GLB' },
      ],
      snackbar: {
        text: '',
        visibility: false,
      }
    };
  },
  components: {
    NavigationView,
  },
  computed: {
    displayScreenshots() {
      return [...this.app.screenshotPreviews, null].slice(0, 4);
    },
  },
  methods: {
    appsDetail() {
      this.$store.dispatch('appsDetail', {
        aid: this.aid
      })
      .then((result) => {
        if (result.success) {
          this.app.preload = this.$store.getters.getAppsDetail;
          this.app.id = this.app.preload.id;
          this.app.url = this.app.preload.url;
          this.urlToFile(this.app.preload.icon)
          .then(({ file }) => {
            this.app.icon = file;
          })
          .catch(error => console.error('Error:', error));
          this.app.iconPreview = this.app.preload.icon;
          this.app.name = this.app.preload.name;
          this.app.subname = this.app.preload.subname;
          this.app.description = this.app.preload.description;
          for (const url of this.app.preload.screenshots) {
            this.urlToFile(url)
            .then(({ file }) => {
              this.app.screenshots.push(file);
            })
            .catch(error => console.error('Error:', error));
          }
          this.app.screenshotPreviews = this.app.preload.screenshots;
          this.app.category = this.app.preload.category;
          this.app.language = this.app.preload.language;
          this.app.keywords = this.app.preload.keywords;
        }
        else {
          console.log("appsDetail error occured.")
        }
      });
    },
    async urlToFile(imageUrl) {
      const response = await fetch(imageUrl, {
        headers: {
          'Cache-Control': 'no-store, no-cache, must-revalidate, max-age=0',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });
      const blob = await response.blob();
      const fileName = imageUrl.split('/').pop();
      const file = new File([blob], fileName, { type: blob.type });
      return { file };
    },
    iconBtnCkd() {
      this.$refs.fileInput.click();
    },
    myBtnCkd() {
      if(this.$store.getters.isLoggedIn) {
        this.$router.push('/me');
      }
      else {
        this.$router.push('/login');
      }
    },
    completeBtnCkd() {
      if (this.app.url.length == 0 || !functions.isValidUrl(this.app.url)) {
            this.snackbar.text = this.$t('modifyAppUrlInvalid');
            this.snackbar.visibility = true;
            return false;
      }
      if (this.app.icon.length === 0) {
          this.snackbar.text = this.$t('modifyAppIconEmpty');
          this.snackbar.visibility = true;
          return false;
      }
      if (this.app.name.length == 0) {
          this.snackbar.text = this.$t('modifyAppNameEmpty');
          this.snackbar.visibility = true;
          return false;
      }
      if(this.app.name.length > 20) {
          this.snackbar.text = this.$t('modifyAppNameTooLong');
          this.snackbar.visibility = true;
          return false;
      }
      if (this.app.subname.length == 0) {
          this.snackbar.text = this.$t('modifyAppSubnameEmpty');
          this.snackbar.visibility = true;
          return false;
      }
      if(this.app.subname.length > 30) {
          this.snackbar.text = this.$t('modifyAppSubnameTooLong');
          this.snackbar.visibility = true;
          return false;
      }
      if (this.app.description.length == 0) {
          this.snackbar.text = this.$t('modifyAppDescriptionEmpty');
          this.snackbar.visibility = true;
          return false;
      }
      if(this.app.description.length > 1000) {
          this.snackbar.text = this.$t('modifyAppDescriptionTooLong');
          this.snackbar.visibility = true;
          return false;
      }
      if (this.app.language == '') {
          this.snackbar.text = this.$t('modifyAppLanguageEmpty');
          this.snackbar.visibility = true;
          return false;
      }
      if (this.app.keywords == '') {
          this.snackbar.text = this.$t('modifyAppKeywordEmpty');
          this.snackbar.visibility = true;
          return false;
      }
      if ((this.app.keywords.split(',').map(keyword => keyword.trim()).filter(keyword => keyword !== '')).length > 10) {
          this.snackbar.text = this.$t('modifyAppKeywordTooLong');
          this.snackbar.visibility = true;
          return false;
      }
      this.$store.dispatch('setLoading', true);
      this.$store.dispatch('appsModify', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        aid: this.app.id,
        url: this.app.url,
        icon: this.app.icon,
        name: this.app.name,
        subname: this.app.subname,
        description: this.app.description,
        screenshots: this.app.screenshots,
        category: this.app.category,
        language: this.app.language,
        keywords: this.app.keywords,
      })
      .then((result) => {
        if(result.success) {
          this.completeTrigger = true;
          this.$router.push('/my-app');
        }
        else {
          switch (result.code) {
            case '409':
              this.snackbar.text = this.$t('modifyAppAppConflict');
              break;
            default:
              this.snackbar.text = this.$t('serverError');
              break;
          }
          this.snackbar.visibility = true;
        }
      })
      .finally(() => {
        this.$store.dispatch('setLoading', false);
      });
    },
    backBtnCkd() {
      this.$router.go(-1);
    },
    selectIconBtnCkd() {
      this.$refs.imageInput.click();
    },
    onIconSelected(event) {
      const file = event.target.files[0];
      if (file) {    
        this.app.icon = file;
        this.app.iconPreview = URL.createObjectURL(file);
        this.isIconSelected = true;
      }
    },
    selectScreenshotsBtnCkd() {
      if (this.app.screenshots.length < 4) {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = (event) => {
          const files = event.target.files;
          for (let i = 0; i < files.length && this.app.screenshots.length < 4; i++) {
            const file = files[i];
            this.app.screenshots.push(file);
            const reader = new FileReader();
            reader.onload = (e) => {
              this.$set(this.app.screenshotPreviews, this.app.screenshots.length - 1, e.target.result);
            };
            reader.readAsDataURL(file);
          }
        };
        input.click();
      }
    },
    deleteScreenshot(index) {
      this.app.screenshots.splice(index, 1);
      this.app.screenshotPreviews.splice(index, 1);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if(!(this.$store.getters.isLoggedIn)) {
      this.$router.push('/');
      return;
    }
    if(this.$route.query.id) {
      this.aid = this.$route.query.id;
    }
    else {
      this.backBtnCkd();
    }
    this.profile.name = this.$store.getters.getName;
    this.profile.profileImage = this.$store.getters.getProfileImage;

    this.appsDetail();
  },
  beforeRouteLeave(to, from, next) {
    if(!(this.completeTrigger)) {
      const answer = window.confirm(this.$t('alertBack'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    }
    else {
      next();
    }
  }
};
</script>

<style scoped>

/* icon */

.transparent-btn {
  background-color: transparent !important;
  box-shadow: none !important;
}

/* screenshots */
.overflow-x-auto {
  overflow-x: auto;
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.overflow-x-auto::-webkit-scrollbar {
  display: none;  /* WebKit */
}
.scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

/* screenshot delete btns */
.screenshot-container {
  position: relative;
}
.delete-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.screenshot-container:hover .delete-btn {
  opacity: 1;
}
@media (max-width: 768px) {
  .delete-btn {
    display: none;
  }
}

/* description */
.app-description-textarea {
  background-color: transparent !important;
}
.app-description-textarea >>> .v-input__control {
  border: none !important;
}
.app-description-textarea >>> .v-input__slot {
  box-shadow: none !important;
}
.app-description-textarea >>> .v-input__slot:hover {
  box-shadow: none !important;
}
.app-description-textarea >>> .v-input__slot:focus {
  box-shadow: none !important;
}
.app-description-textarea >>> .v-input__slot:active {
  box-shadow: none !important;
}

</style>