import api from '../../rest/api';

export default {
  state: {
    library: {
      apps: [],
      oapps: [],
    }
  },
  mutations: {
    setLibraryApps(state, apps) {
      state.library.apps = apps;
    },
    addLibraryApp(state, app) {
      state.library.apps.push(app);
    },
    removeLibraryApp(state, id) {
      state.library.apps = state.library.apps.filter(item => item.id !== id);
    },
    addLibraryOApps(state, app) {
      state.library.oapps.push(app);
    },
    removeLibraryOApp(state, id) {
      state.library.oapps = state.library.oapps.filter(item => item.id !== id);
    }
  },
  getters: {
    getLibraryApps(state) {
      return state.library.apps;
    },
    getLibraryOApps(state) {
      return state.library.oapps;
    },
  },
  actions: {
    async libraryGet({ commit }, { accessToken, uid }) {
      try {
        const response = await api.libraryGet(accessToken, uid);
        const code = response.data.code;
        if(code == "200") {
          commit('setLibraryApps', response.data.data);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
    async libraryAdd({ commit, state }, { isLoggedIn, accessToken, uid, id, url, icon, name, subname }) {
      if (isLoggedIn) {
        try {
          const response = await api.libraryAdd(accessToken, uid, id);
          const code = response.data.code;
          if(code == "200") {
            const app = { id: id, url, icon, name, subname };
            commit('addLibraryApp', app);
            return { success: true };
          }
          else {
            return { success: false, code: code};
          }
        } catch (error) {
          console.error('Failed:', error);
          return { success: false, code: "500"};
        }
      } else {
        const isOAppExisted = state.library.oapps.find(item => item.url === url);
        if (isOAppExisted) {
          return { 
            success: false, code: '409',
          };
        } else {
          const app = { id: id, url, icon, name, subname };
          commit('addLibraryOApps', app);
          return { success: true };
        }
      }
    },
    async libraryRemove({ commit }, { isLoggedIn, accessToken, uid, aid }) {
      if (isLoggedIn) {
        try {
          const response = await api.libraryRemove(isLoggedIn, accessToken, uid, aid);
          const code = response.data.code;
          if(code == "200") {
            commit('removeLibraryApp', aid);
            return { success: true };
          }
          else {
            return { success: false, code: code};
          }
        } catch (error) {
          console.error('Failed:', error);
          return { success: false, code: "500"};
        }
      } else {
        commit('removeLibraryOApp', aid);
        return { success: true };
      }
    },
  }
};