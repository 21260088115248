<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1280px;">

      <navigation-view
        :profile-image="profile.profileImage"
        @my-btn-clicked="myBtnCkd"
      />

      <v-container class="ma-0" fluid>
        <v-text-field
          v-model="search.keyword"
          :placeholder="$t('searchHint')"
          append-icon="mdi-magnify"
          color="var(--theme)"
          @click:append="searchBtnCkd"
          @keyup.enter="searchBtnCkd"
          clearable
          rounded
          filled
          hide-details
          :style="{ 'box-shadow': 'none' }"
        />
      </v-container>

      <v-container class="ma-0 ml-1" fluuid v-if="search.noData">
        <p>{{ $t('searchNoItems') }}</p>
      </v-container>

      <v-container class="ma-0" style="margin-bottom: 60px !important;" fluid>
        <v-row>

          <v-col 
            v-for="(app, index) in apps.searches" 
            :key="`apps-searches-${index}`" 
            cols="12" 
            sm="6" 
            md="4"
            @click="appDetailBtnCkd(app.id)"
            style="cursor: pointer;"
          >
            <v-card flat elevation="0" class="mb-3">
              <v-row align="center" no-gutters>
                <v-col cols="auto" class="mr-3">
                  <v-img 
                    class="app-icon app-icon-elevation"
                    :src="app.icon" 
                  />
                </v-col>
                
                <v-col class="pa-0 text-left">
                  <div>
                    <div class="text-subtitle-1 font-weight-medium font-weight-bold">{{ app.name }}</div>
                    <div class="text-body-2 grey--text">{{ app.subname }}</div>
                  </div>
                </v-col>

                <v-col cols="auto">
                  <v-btn icon small class="mr-2 rounded" width="20" height="20" @click.stop @click="appLibraryAddBtnCkd(app.id, app.url, app.icon, app.name, app.subname)" v-if="app.can_library == 'true'">
                    <v-img :src="require('@/assets/ic_library.svg')" width="20" height="20"/>
                  </v-btn>
                  <v-btn 
                    class="add-home-btn" 
                    height="36" 
                    elevation="0"
                    :style="{ borderRadius: '16px' }"
                    @click.stop
                    @click="appAddBtnCkd(app.url, app.name)"
                  >
                    추가 +
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

        </v-row>
      </v-container>

      <AHMView :show="AHMView.show" @close="AHMView.show = false">

        <template v-slot:header>
          <div class="d-flex align-center">
            <v-img
              :src="require('@/assets/ic_rolling.gif')"
              style="width: 20px; height: 20px; max-width: 20px; max-height: 20px;"
              class="mr-2"
              contain
            />
            <h4 class="mb-0">{{ $t('ahmvTitle') }}</h4>
          </div>
        </template>

        <template v-slot:body>

          <template v-if="AHMView.platform === 'mobile_chrome'">
            <!-- mobile_chrome 템플릿 -->
            <p v-html="$t('ahmvMobileChrome1', { url: AHMView.url, name: AHMView.name })"></p>
            <p v-html="$t('ahmvMobileChrome2')"></p>
            <p v-html="$t('ahmvMobileChrome3')"></p>
            <p v-html="$t('ahmvMobileChrome4')"></p>
          </template>

          <template v-else-if="AHMView.platform === 'mobile_safari'">
            <!-- mobile_safari 템플릿 -->
            <p v-html="$t('ahmvMobileSafari1', { url: AHMView.url, name: AHMView.name })"></p>
            <p style="display: inline; vertical-align: middle;">
              <span v-html="$t('ahmvMobileSafari2Part1')"></span>
              <v-img :src="require('@/assets/ic_ahmv_safari_share.svg')" max-width="20" max-height="20" contain class="mx-1 mb-1" style="vertical-align: middle; display: inline-block;" />
              <span v-html="$t('ahmvMobileSafari2Part2')"></span>
            </p>
            <p class="mt-4" v-html="$t('ahmvMobileSafari3')"></p>
            <p v-html="$t('ahmvMobileSafari4')"></p>
          </template>
          
          <template v-else-if="AHMView.platform === 'mobile_other'">
            <!-- mobile_other 템플릿 -->
            <p v-html="$t('ahmvMobileOther1', { url: AHMView.url, name: AHMView.name })"></p>
            <p v-html="$t('ahmvMobileOther2')"></p>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>{{ $t('ahmvMobileOther3') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div style="max-height: 250px; overflow-y: auto;">
                      <p v-html="$t('pwaMobileTitle')" class="text-h6 font-weight-bold mb-1"></p>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaSamsungAndroid')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaSamsungAndroid1')" class="mt-2"></p>
                        <p v-html="$t('pwaSamsungAndroid2')" class="mt-1"></p>
                        <p v-html="$t('pwaSamsungAndroid3')" class="mt-1"></p>
                        <p v-html="$t('pwaSamsungAndroid4')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaFirefoxAndroid')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaFirefoxAndroid1')" class="mt-2"></p>
                        <p v-html="$t('pwaFirefoxAndroid2')" class="mt-1"></p>
                        <p v-html="$t('pwaFirefoxAndroid3')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaEdgeAndroid')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaEdgeAndroid1')" class="mt-2"></p>
                        <p v-html="$t('pwaEdgeAndroid2')" class="mt-1"></p>
                        <p v-html="$t('pwaEdgeAndroid3')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaFirefoxIOS')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaFirefoxIOS1')" class="mt-2"></p>
                        <p v-html="$t('pwaFirefoxIOS2')" class="mt-1"></p>
                        <p v-html="$t('pwaFirefoxIOS3')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaEdgeIOS')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaEdgeIOS1')" class="mt-2"></p>
                        <p v-html="$t('pwaEdgeIOS2')" class="mt-1"></p>
                        <p v-html="$t('pwaEdgeIOS3')" class="mt-1 mb-0"></p>
                      </v-container>
                      <p v-html="$t('pwaDesktopTitle')" class="text-h6 font-weight-bold mt-3 mb-1"></p>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaEdgeDesktop')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaEdgeDesktop1')" class="mt-2"></p>
                        <p v-html="$t('pwaEdgeDesktop2')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaSafariMacOS')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaSafariMacOS1')" class="mt-2"></p>
                        <p v-html="$t('pwaSafariMacOS2')" class="mt-1"></p>
                        <p v-html="$t('pwaSafariMacOS3')" class="mt-1 mb-0"></p>
                      </v-container>

                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
          
          <template v-else-if="AHMView.platform === 'desktop_chrome'">
            <!-- desktop_chrome 템플릿 -->
            <p v-html="$t('ahmvDesktopChrome1', { url: AHMView.url, name: AHMView.name })"></p>
            <p style="display: inline; vertical-align: middle;">
              <span v-html="$t('ahmvDesktopChrome2Part1')"></span>
              <v-img :src="require('@/assets/ic_ahmv_chrome_download.svg')" max-width="20" max-height="20" contain class="mx-1 mb-1" style="vertical-align: middle; display: inline-block;" />
              <span v-html="$t('ahmvDesktopChrome2Part2')"></span>
            </p>
            <p class="mt-4" v-html="$t('ahmvDesktopChrome3')"></p>
          </template>
          
          <template v-else-if="AHMView.platform === 'desktop_safari'">
            <!-- desktop_safari 템플릿 -->
            <p v-html="$t('ahmvDesktopSafari1', { url: AHMView.url, name: AHMView.name })"></p>
            <p style="display: inline; vertical-align: middle;">
              <span v-html="$t('ahmvDesktopSafari2Part1')"></span>
              <v-img :src="require('@/assets/ic_ahmv_safari_share.svg')" max-width="20" max-height="20" contain class="mx-1 mb-1" style="vertical-align: middle; display: inline-block;" />
              <span v-html="$t('ahmvDesktopSafari2Part2')"></span>
            </p>
            <p class="mt-4" v-html="$t('ahmvDesktopSafari3')"></p>
            <p v-html="$t('ahmvDesktopSafari4')"></p>
          </template>
          
          <template v-else>
            <!-- desktop_other 템플릿 -->
            <p v-html="$t('ahmvDesktopOther1', { url: AHMView.url, name: AHMView.name })"></p>
            <p v-html="$t('ahmvDesktopOther2')"></p>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>{{ $t('ahmvDesktopOther3') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div style="max-height: 250px; overflow-y: auto;">
                      <p v-html="$t('pwaMobileTitle')" class="text-h6 font-weight-bold mb-1"></p>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaSamsungAndroid')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaSamsungAndroid1')" class="mt-2"></p>
                        <p v-html="$t('pwaSamsungAndroid2')" class="mt-1"></p>
                        <p v-html="$t('pwaSamsungAndroid3')" class="mt-1"></p>
                        <p v-html="$t('pwaSamsungAndroid4')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaFirefoxAndroid')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaFirefoxAndroid1')" class="mt-2"></p>
                        <p v-html="$t('pwaFirefoxAndroid2')" class="mt-1"></p>
                        <p v-html="$t('pwaFirefoxAndroid3')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaEdgeAndroid')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaEdgeAndroid1')" class="mt-2"></p>
                        <p v-html="$t('pwaEdgeAndroid2')" class="mt-1"></p>
                        <p v-html="$t('pwaEdgeAndroid3')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaFirefoxIOS')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaFirefoxIOS1')" class="mt-2"></p>
                        <p v-html="$t('pwaFirefoxIOS2')" class="mt-1"></p>
                        <p v-html="$t('pwaFirefoxIOS3')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaEdgeIOS')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaEdgeIOS1')" class="mt-2"></p>
                        <p v-html="$t('pwaEdgeIOS2')" class="mt-1"></p>
                        <p v-html="$t('pwaEdgeIOS3')" class="mt-1 mb-0"></p>
                      </v-container>
                      <p v-html="$t('pwaDesktopTitle')" class="text-h6 font-weight-bold mt-3 mb-1"></p>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaEdgeDesktop')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaEdgeDesktop1')" class="mt-2"></p>
                        <p v-html="$t('pwaEdgeDesktop2')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaSafariMacOS')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaSafariMacOS1')" class="mt-2"></p>
                        <p v-html="$t('pwaSafariMacOS2')" class="mt-1"></p>
                        <p v-html="$t('pwaSafariMacOS3')" class="mt-1 mb-0"></p>
                      </v-container>

                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>

        </template>

      </AHMView>

      <v-snackbar style="margin-bottom: 60px;" v-model="snackbar.visibility">
      <v-container class="text-center pd-0" style="padding: 0;">
        {{ snackbar.text }}
      </v-container>
      <template v-slot:actions>
        </template>
      </v-snackbar>

    </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationView from './elements/NavigationView.vue'
import AHMView from './AHMView.vue'
import functions from '@/rest/functions';

export default {
  name: "SearchView",
  components: {
    NavigationView,
    AHMView
  },
  data() {
    return {
      AHMView: {
        show: false,
        url: '',
        name: '',
        platform: '',
      },
      profile: {
        profileImage: require('@/assets/ic_tab_profile.svg'),
      },
      apps: {
        searches: [],
      },
      search: {
        keyword: '',
        noData: false,
      },
      snackbar: {
        text: "",
        visibility: false,
      },
    }
  },
  methods: {
    searching() {
      this.apps.searches = [];
      this.search.noData = false;
      this.$store.dispatch('appsSearches', {
        keyword: this.search.keyword
      })
      .then((result) => {
        if (result.success) {
          const newSearches = this.$store.getters.getAppsSearches;
          if(newSearches.length == 0) {
            this.search.noData = true;
            return;
          }
          const uniqueSearches = Array.from(new Map([...this.apps.searches, ...newSearches].map(app => [app.id, app])).values());
          this.apps.searches = uniqueSearches;
        }
        else {
          console.log("appsSearches error occured.")
        }
      });
    },
    searchBtnCkd() {
        if (this.search.keyword.trim()) {
          this.$router.replace({
          path: '/search',
          query: { 
            k: this.search.keyword,
            _t: Date.now() 
          }
        });
        this.searching();
      }
    },
    myBtnCkd() {
      if(this.$store.getters.isLoggedIn) {
        this.$router.push('/me');
      }
      else {
        this.$router.push('/login');
      }
    },
    appAddBtnCkd(url, name) {
      this.AHMView.url = url;
      this.AHMView.name = name;
      this.AHMView.show = true;
    },
    appLibraryAddBtnCkd(id, url, icon, name, subname) {
      this.$store.dispatch('libraryAdd', {
        isLoggedIn: this.$store.getters.isLoggedIn,
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        id: id,
        url: url,
        icon: icon,
        name: name,
        subname: subname
      })
      .then((result) => {
        if(result.success) {
          this.snackbar.text = this.$t('libraryAdded');
          this.snackbar.visibility = true;
          this.appUpdateInteractions(id);
        }
        else {
          switch (result.code) {
            case '409':
              this.snackbar.text = this.$t('libraryAlreadyExist');
              this.snackbar.visibility = true;
              break;
            default:
              this.snackbar.text = this.$t('serverError');
              this.snackbar.visibility = true;
              break;
          }
        }
      })
    },
    appDetailBtnCkd(appId) {
    this.$router.push({ path: '/app-detail', query: { id: appId } });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.AHMView.platform = functions.getPlatform();
    if(this.$route.query.k) {
      this.search.keyword = this.$route.query.k;
      this.searching();
    }
    this.profile.profileImage = this.$store.getters.getProfileImage || require('@/assets/ic_tab_profile.svg');
  }
};
</script>

<style scoped>

.add-home-btn {
  font-size: 12px !important;
  color: white !important;
  background-color: var(--theme) !important;
}

</style>