<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1280px;">

        <navigation-view
          :profile-image="profile.profileImage"
          @my-btn-clicked="myBtnCkd"
        />

        <v-container class="my-3 pa-0 d-flex align-center" fluid>
          <v-btn 
            @click="backBtnCkd" 
            text
            color="black"
          >
            <v-icon class="back-icon">mdi-chevron-left</v-icon>
            <span>{{ $t('libraryManageTitle') }}</span>
          </v-btn>
        </v-container>

        <v-container fluid>
          <v-row>
            <v-col
              class="mt-3"
              v-for="(app, index) in apps.library" 
              :key="`apps-library-${index}`" 
              cols="12"
            >
              <v-card flat elevation="0">
                <v-row align="center" no-gutters>
                  <v-col cols="auto" class="mr-3">
                    <v-img 
                      class="app-icon-elevation"
                      :src="app.icon" 
                      height="70px" 
                      width="70px"
                      :style="{ borderRadius: '16px' }"
                    />
                  </v-col>
                  
                  <v-col class="pa-0 text-left">
                    <div>
                      <div class="text-subtitle-1 font-weight-medium font-weight-bold">{{ app.name }}</div>
                      <div class="text-body-2 grey--text">{{ app.subname }}</div>
                    </div>
                  </v-col>

                  <v-col cols="auto">
                    <v-btn
                      icon
                      color="white"
                      x-small
                      style="background-color: var(--red);border-radius:50%;"
                      @click="removeBtnCkd(app.id)"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                  
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationView from './elements/NavigationView.vue'
export default {
  name: 'LibraryView',
  data() {
    return {
      profile: {
        name: '',
        profileImage: require('@/assets/ic_tab_profile.svg'),
      },
      apps: {
        library: [],
      }
    };
  },
  components: {
    NavigationView,
  },
  methods: {
    libraryGet() {
      if(this.$store.getters.isLoggedIn) {
        this.$store.dispatch('libraryGet', {
          isLoggedIn: this.$store.getters.isLoggedIn,
          accessToken: this.$store.getters.getAccessToken,
          uid: this.$store.getters.getUID,
        })
        .then((result) => {
          if(result) {
          this.apps.library = this.$store.getters.getLibraryApps; 
          }
          else {
            this.snackbar.text = this.$t('serverError');
            this.snackbar.visibility = true;
          }
        })
      }
      else {
        this.apps.library = this.$store.getters.getLibraryOApps;
      }
    },
    removeBtnCkd(id) {
      this.$store.dispatch('libraryRemove', {
        isLoggedIn: this.$store.getters.isLoggedIn,
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        aid: id,
      })
      .then((result) => {
        if(result.success) {
          this.apps.library = this.apps.library.filter(app => app.id !== id);
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      })
    },
    myBtnCkd() {
      if(this.$store.getters.isLoggedIn) {
        this.$router.push('/me');
      }
      else {
        this.$router.push('/login');
      }
    },
    backBtnCkd() {
      this.$router.go(-1);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if(this.$store.getters.isLoggedIn) {
      this.profile.name = this.$store.getters.getName;
      this.profile.profileImage = this.$store.getters.getProfileImage;
    }
    this.libraryGet();
  },
};
</script>

<style scoped>

.status-text {
  display: flex;
  align-items: center;
}

</style>