<template>
  <div id="app">
    <router-view></router-view>
    <LoadingView :is-loading="$store.getters.isLoading" />
    <footer-view v-if="!$route.meta.hideFooter"></footer-view>
  </div>
</template>

<script>
import FooterView from './components/elements/FooterView.vue'
import LoadingView from './components/elements/LoadingView.vue'

export default {
  name: 'App',
  data: () => ({
  }),
  metaInfo() {
    return {
      title: this.$t('meta.title'),
      meta: [
        { name: 'description', content: this.$t('meta.description') },
        { name: 'keywords', content: this.$t('meta.keywords') },
        // Open Graph
        { property: 'og:title', content: this.$t('meta.title') },
        { property: 'og:description', content: this.$t('meta.description') },
        { name: 'og:image', content: require('@/assets/og_image.png') },
        { property: 'og:url', content: window.location.href },
        { property: 'og:type', content: 'website' },
        // // Twitter Card
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.$t('meta.title') },
        { name: 'twitter:description', content: this.$t('meta.description') },
        { name: 'twitter:image', content: require('@/assets/og_image.png') },
      ]
    }
  },
  components: {
    FooterView,
    LoadingView
  },
  mounted() {
    window.scrollTo(0, 0);
  },
}
</script>

<style>


/* common */

:root {
  --gray: #F2F2F2;
  --gray-dark: #AFAFAF;
  --red: #F95858;
  --theme: #5757FF;
  --theme-light: #F5F5FF;
}

.v-app-bar {
  box-shadow: none;
}

.v-btn::before {
  background-color: transparent !important;
}

.v-btn:not(.v-btn--active):focus::before {
  opacity: 0 !important;
}

.v-btn {
  /* 대문자 비활성 설정 */
  text-transform: none !important; 
}

/* scrollbars */

::-webkit-scrollbar {
  display: none;
}
* {
  scrollbar-width: none;
}
* {
  -ms-overflow-style: none;
}

/* components */

.theme--text {
  color: var(--theme) !important;
}
.dark-gray--text {
  color: var(--gray-dark) !important;
}

.highlight {
  color: var(--theme) !important;
  font-weight: bold;
}

.back-icon {
  background-color: var(--gray);
  border-radius: 50%;
  padding: 4px;
  margin-right: 8%;
}

.app-icon-elevation {
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  display: inline-block;
  transition: box-shadow 0.3s ease;
}

/* apps */

.app-icon {
  width: 60px;
  height: 60px;
  border-radius: 16px;
}

@media screen and (max-width: 847px) {
  .app-icon {
    width: 50px;
    height: 50px;
    border-radius: 8px;
  }
}
@media screen and (max-width: 599px) {
  .app-icon {
    width: 50px;
    height: 50px;
    border-radius: 8px;
  }
}

</style>
