<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1280px;">

      <navigation-view
        :profile-image="profile.profileImage"
        @my-btn-clicked="myBtnCkd"
      />

        <v-row align="center" justify="center" class="ma-0 mb-5 py-8" style="background-color: var(--theme-light); border-radius: 16px;">
          <v-col class="pa-0" align="center">
            <img 
              class="app-icon app-icon-elevation"
              :src="require('@/assets/ic_developer_product.png')" 
            />
            <p class="text-body-1 mt-4 mb-0 pa-0">19,900₩ / {{ $t('developerProduct') }}</p>
          </v-col>
        </v-row>
        
        <div class="d-flex align-center justify-center mt-10">
          <p class="pa-0 ma-0"><b>Aeida 개발자 등록완료</b></p>
          <v-icon color="green" size="16" class="ml-1">mdi-check-circle</v-icon>
        </div>
        
        <v-container class="d-flex flex-column align-center mt-8 pa-0" fluid>

          <v-btn 
            to="/new-app"
            text
            color="white"
            class="mb-5"
            width="200"
            style="background-color: var(--theme);"
          >
            <span class="mr-2"><b>신규 앱 등록</b></span>
          </v-btn>

          <v-btn 
            href="/app-guidelines"
            target="_blank"
            text
            color="black"
            class="mb-5"
            width="200"
          >
            <span class="mr-2">앱 검토 지침</span>
          </v-btn>

          <v-btn
            href="mailto:aeida.store@gmail.com"
            target="_blank"
            text
            color="black"
            class="mb-5"
            width="200"
          >
            <span class="mr-2">개발자 프로그램 관련 문의</span>
          </v-btn>

        </v-container>
        

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationView from './elements/NavigationView.vue'
export default {
  name: 'DeveloperRegisterCompleteView',
  data() {
    return {
      profile: {
        profileImage: require('@/assets/ic_tab_profile.svg'),
      },
    };
  },
  components: {
    NavigationView
  },
  methods: {
    myBtnCkd() {
      if(this.$store.getters.isLoggedIn) {
        this.$router.push('/me');
      }
      else {
        this.$router.push('/login');
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if(!(this.$store.getters.isLoggedIn)) {
      this.$router.push('/');
      return;
    }
    this.profile.profileImage = this.$store.getters.getProfileImage;
  }
};
</script>

<style scoped>

</style>