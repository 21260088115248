import api from '../../rest/api';

const initialState = {
  apps: {
    recommends: [],
    all: [],
    searches: [],
    my: [],
    detail: [],
  },
};

export default {
  state: { ...initialState },
  mutations: {
    setAppsRecommends(state, recommends) {
      state.apps.recommends = recommends;
    },
    setAppsAll(state, all) {
      state.apps.all = all;
    },
    setAppsSearches(state, searches) {
      state.apps.searches = searches;
    },
    setAppsMy(state, my) {
      state.apps.my = my;
    },
    setAppsDetail(state, detail) {
      if (detail.screenshots) {
        detail.screenshots = detail.screenshots.split(',').map(url => url.trim());
      } else {
        detail.screenshots = [];
      }
      state.apps.detail = detail;
    },
  },
  getters: {
    getAppsRecommends(state) {
      return state.apps.recommends;
    },
    getAppsAll(state) {
      return state.apps.all;
    },
    getAppsSearches(state) {
      return state.apps.searches;
    },
    getAppsMy(state) {
      return state.apps.my;
    },
    getAppsDetail(state) {
      return state.apps.detail;
    },
  },
  actions: {
    async appsAdd( _, { accessToken, uid, url, icon, name, subname, description, screenshots, category, language, keywords} ) {
      try {
        const response = await api.appsAdd(accessToken, uid, url, icon, name, subname, description, screenshots, category, language, keywords);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
    async appsModify( _, { accessToken, uid, aid, url, icon, name, subname, description, screenshots, category, language, keywords} ) {
      try {
        const response = await api.appsModify(accessToken, uid, aid, url, icon, name, subname, description, screenshots, category, language, keywords);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
    async appsRecommends({ commit }) {
      try {
        const response = await api.appsRecommends();
        const code = response.data.code;
        if(code == "200") {
          commit('setAppsRecommends', response.data.data);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
    async appsAll({ commit }, {offset, loadCount}) {
      try {
        const response = await api.appsAll(offset, loadCount);
        const code = response.data.code;
        if(code == "200") {
          commit('setAppsAll', response.data.data);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
    async appsSearches({ commit }, { keyword }) {
      try {
        const response = await api.appsSearches(keyword);
        const code = response.data.code;
        if(code == "200") {
          commit('setAppsSearches', response.data.data);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
    async appsMy({ commit }, { accessToken, uid }) {
      try {
        const response = await api.appsMy(accessToken, uid);
        const code = response.data.code;
        if(code == "200") {
          commit('setAppsMy', response.data.data);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
    async appsDetail({ commit }, { aid }) {
      try {
        const response = await api.appsDetail(aid);
        const code = response.data.code;
        if(code == "200") {
          commit('setAppsDetail', response.data.data);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
    async appsUpdateViews( _, { aid }) {
      try {
        const response = await api.appsUpdateViews(aid);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
    async appsUpdateInteractions( _, { aid }) {
      try {
        const response = await api.appsUpdateInteractions(aid);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
    async appsReport( _, { accessToken, uid, aid, reason }) {
      try {
        const response = await api.appsReport(accessToken, uid, aid, reason);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
  },
};