<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1280px;">

        <navigation-view
          :profile-image="profile.profileImage"
          @my-btn-clicked="myBtnCkd"
        />

        <v-container class="my-3 pa-0 d-flex align-center" fluid>
          <v-btn 
            @click="backBtnCkd" 
            text
            color="black"
          >
            <v-icon class="back-icon">mdi-chevron-left</v-icon>
            <span>{{ $t('inquiryTitle') }}</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="var(--theme)"
            rounded 
            v-on:click="completeBtnCkd"
            class="px-5 mr-3 white--text font-weight-bold"
          >
            {{ $t('inquirySubmit') }}
          </v-btn>
        </v-container>

        <v-container fluid>

          <v-row class="mb-2">
            <v-col class="pb-0">
              <v-text-field 
                :label="$t('inquiryEmail')"
                rows="5" 
                auto-grow
                v-model="email"
                hide-details 
                color="black" 
                flat
                solo
                style="border-radius: 16px;"
                background-color="var(--gray)"
                class="ipt mt-0">
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pb-1">
              <v-textarea 
                :label="$t('inquiryContents')"
                rows="5" 
                auto-grow
                v-model="contents"
                hide-details 
                color="black" 
                flat
                solo
                style="border-radius: 16px;"
                background-color="var(--gray)"
                class="ipt mt-0">
              </v-textarea>
            </v-col>
          </v-row>

        </v-container>

        <!-- <v-container fluid>

          <v-card flat elevation="0">
            <v-row align="center" no-gutters>

              <v-col cols="auto" class="mr-3">
                <v-img 
                  :src="app.detail.icon" 
                  height="70px" 
                  width="70px"
                  :style="{ borderRadius: '16px' }"
                />
              </v-col>
              
              <v-col class="pa-0 text-left">
                <div>
                  <div class="text-h5 font-weight-medium font-weight-bold">{{ app.detail.name }}</div>
                  <div class="text-subtitle-1 grey--text">{{ app.detail.subname }}</div>
                </div>
              </v-col>

            </v-row>
          </v-card>

          <v-textarea
            v-model="reason"
            :placeholder="$t('appReportPlaceholder')"
            flat
            solo
            full-width
            height="300"
            hide-details
            class="app-description-textarea mt-7"
            style="border-radius: 16px;"
            background-color="var(--gray)"
          ></v-textarea>

        </v-container> -->

        <v-snackbar style="margin-bottom: 60px;" v-model="snackbar.visibility">
          <v-container class="text-center pd-0" style="padding: 0;">
            {{ snackbar.text }}
          </v-container>
          <template v-slot:actions>
            </template>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationView from './elements/NavigationView.vue'
import functions from '@/rest/functions';

export default {
  name: 'InquiryView',
  data() {
    return {
      email: '',
      contents: '',
      profile: {
        name: '',
        profileImage: require('@/assets/ic_tab_profile.svg'),
      },
      snackbar: {
        text: "",
        visibility: false,
      }
    };
  },
  components: {
    NavigationView
  },
  methods: {
    completeBtnCkd() {
      if(this.email == '') {
        this.snackbar.text = this.$t('inquiryEmailEmpty');
        this.snackbar.visibility = true;
        return;
      }
      if(!functions.isValidEmail(this.email)) {
        this.snackbar.text = this.$t('inquiryEmailInvalid');
        this.snackbar.visibility = true;
        return;
      }
      if(this.contents == '') {
        this.snackbar.text = this.$t('inquiryContentsEmpty');
        this.snackbar.visibility = true;
        return;
      }
      if(this.contents.length > 5000) {
        this.snackbar.text = this.$t('inquiryContentsTooLong');
        this.snackbar.visibility = true;
        return;
      }
      this.$store.dispatch('inquiryWrite', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        email: this.email,
        contents: this.contents,
      })
      .then((result) => {
        if (result.success) {
          this.backBtnCkd();
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      });
    },
    myBtnCkd() {
      if(this.$store.getters.isLoggedIn) {
        this.$router.push('/me');
      }
      else {
        this.$router.push('/login');
      }
    },
    backBtnCkd() {
      this.$router.go(-1);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if(!(this.$store.getters.isLoggedIn)) {
      this.$router.push('/');
    }
    this.profile.profileImage = this.$store.getters.getProfileImage;
  },
};
</script>

<style scoped>

</style>