<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1280px;">

      <navigation-view
        :profile-image="profile.profileImage"
        @my-btn-clicked="myBtnCkd"
      />

        <v-container class="my-3 pa-0 d-flex align-center" fluid>
          <v-btn 
            @click="backBtnCkd" 
            text
            color="black"
          >
            <v-icon class="back-icon">mdi-chevron-left</v-icon>
            <span>{{ $t('appReportTitle') }}</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="var(--theme)"
            rounded 
            v-on:click="completeBtnCkd"
            class="px-5 mr-3 white--text font-weight-bold"
          >
            {{ $t('appReportComplete') }}
          </v-btn>
        </v-container>

        <v-container fluid>

          <v-card flat elevation="0">
            <v-row align="center" no-gutters>

              <v-col cols="auto" class="mr-3">
                <v-img 
                  :src="app.detail.icon" 
                  height="70px" 
                  width="70px"
                  :style="{ borderRadius: '16px' }"
                />
              </v-col>
              
              <v-col class="pa-0 text-left">
                <div>
                  <div class="text-h5 font-weight-medium font-weight-bold">{{ app.detail.name }}</div>
                  <div class="text-subtitle-1 grey--text">{{ app.detail.subname }}</div>
                </div>
              </v-col>

            </v-row>
          </v-card>

          <v-textarea
            v-model="reason"
            :placeholder="$t('appReportPlaceholder')"
            flat
            solo
            full-width
            height="300"
            hide-details
            class="mt-7"
            style="border-radius: 16px;"
            background-color="var(--gray)"
          ></v-textarea>

        </v-container>

        <v-snackbar style="margin-bottom: 60px;" v-model="snackbar.visibility">
          <v-container class="text-center pd-0" style="padding: 0;">
            {{ snackbar.text }}
          </v-container>
          <template v-slot:actions>
            </template>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationView from './elements/NavigationView.vue'
export default {
  name: 'AppReportView',
  data() {
    return {
      reason: '',
      profile: {
        name: '',
        profileImage: require('@/assets/ic_tab_profile.svg'),
      },
      app: {
        aid: null,
        detail: [],
      },
      snackbar: {
        text: "",
        visibility: false,
      }
    };
  },
  components: {
    NavigationView
  },
  methods: {
    appsDetail() {
      this.$store.dispatch('appsDetail', {
        aid: this.app.aid
      })
      .then((result) => {
        if (result.success) {
          this.app.detail = this.$store.getters.getAppsDetail;
        }
        else {
          console.log("appsDetail error occured.")
        }
      });
    },
    completeBtnCkd() {
      if(this.reason == '') {
        this.snackbar.text = this.$t('appReportReasonEmpty');
        this.snackbar.visibility = true;
        return;
      }
      this.$store.dispatch('appsReport', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        aid: this.app.aid,
        reason: this.reason
      })
      .then((result) => {
        if(result) {
          this.$router.push('/');
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      })
    },
    myBtnCkd() {
      if(this.$store.getters.isLoggedIn) {
        this.$router.push('/me');
      }
      else {
        this.$router.push('/login');
      }
    },
    backBtnCkd() {
      this.$router.go(-1);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if(this.$route.query.id) {
      this.app.aid = this.$route.query.id;
    }
    else {
      this.backBtnCkd();
    }
    this.profile.profileImage = this.$store.getters.getProfileImage || require('@/assets/ic_tab_profile.svg');
    this.appsDetail();
  },
};
</script>

<style scoped>

</style>