import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import user from './user';
import apps from './apps';
import library from './library';
import hosted from './hosted';
import authenticate from './authenticate';
import inquiry from './inquiry';
import billing from './billing';
import loading from './loading';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: user,
    apps: apps,
    library: library,
    hosted: hosted,
    authenticate: authenticate,
    inquiry: inquiry,
    billing: billing,
    loading: loading,
  },
  plugins: [(new VuexPersist({
    storage: window.localStorage,
    reducer: (state) => ({ user: state.user, library: state.library, hosted: state.hosted })
  })).plugin]
});