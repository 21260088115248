<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1280px;">

      <navigation-view
        :profile-image="profile.profileImage"
        @my-btn-clicked="myBtnCkd"
      />

      <v-container class="my-3 pa-0" fluid>
        <v-btn 
          @click="backBtnCkd" 
          text
          color="black"
        >
          <v-icon class="back-icon">mdi-chevron-left</v-icon>
          <span> {{ app.detail.name }} </span>
        </v-btn>
      </v-container>

      <v-container class="ma-0" fluid>
        <v-card flat elevation="0">
          <v-row align="center" no-gutters>

            <v-col cols="auto" class="mr-3">
              <img 
                class="app-icon app-icon-elevation"
                :src="app.detail.icon" 
              />
            </v-col>
            
            <v-col class="pa-0 text-left">
              <div>
                <div class="text-h6 font-weight-medium font-weight-bold">{{ app.detail.name }}</div>
                <div class="text-body-2 grey--text">{{ app.detail.subname }}</div>
              </div>
            </v-col>

            <v-col cols="auto">
              <v-menu
                bottom
                left
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click="appReport(app.detail.id)"
                    v-if="this.$store.getters.isLoggedIn"
                  >
                    <v-list-item-title>
                      {{ $t('appDetailReportApp') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="appAuthenticate(app.detail.id)"
                    v-if="this.app.detail.authenticated !== 'true'"
                  >
                    <v-list-item-title>
                      {{ $t('appDetailAuthenticateApp') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>

          </v-row>
        </v-card>
      </v-container>

      <v-container class="ma-0" fluid>
        <v-row>
          <v-col cols="12" v-if="app.detail.can_library == 'true'">
            <v-card class="d-flex flex-column flex-sm-row align-sm-center" elevation="0">
              <v-btn color="var(--gray-dark)" class="white--text mb-2 mb-sm-0 mr-sm-3 pl-3 align-self-start" elevation="0" @click="appLibraryAddBtnCkd(app.detail.id, app.detail.url, app.detail.icon, app.detail.name, app.detail.subname)">
                {{ $t('appDetailAddLibrary') }}
              </v-btn>
              <span class="text-body-2 grey--text">
                {{ $t('appDetailAddLibrarySubtitle') }}
              </span>
            </v-card>
          </v-col>
          <v-col cols="12" class="pt-2">
            <v-card class="d-flex flex-column flex-sm-row align-sm-center" elevation="0">
              <v-btn color="var(--theme)" class="add-home-btn mb-2 mb-sm-0 mr-sm-3 align-self-start" elevation="0" @click="appAddBtnCkd(app.detail.url, app.detail.name)">
                {{ $t('appDetailAddHome') }}
              </v-btn>
              <span class="text-body-2 grey--text">
                {{ $t('appDetailAddHomeSubtitle') }}
              </span>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-divider class="mx-2 my-4"/>
        <div class="scroll-container">
          <v-row no-gutters class="flex-nowrap">

            <v-col cols="auto" class="mx-2">
              <v-card flat class="text-center" min-width="120">
                <v-card-text>
                  <div class="text-body-2 mb-3">
                    {{ $t('appDetailAdded') }}
                  </div>
                  <v-icon size="20" class="mb-3">mdi-download</v-icon>
                  <div class="text-body-1 font-weight-bold">{{ functions.formatNumber(app.detail.downloads) }} +</div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-divider vertical/>

            <v-col cols="auto" class="mx-2">
              <v-card flat class="text-center" min-width="120">
                <v-card-text>
                  <div class="text-body-2 mb-3">
                    {{ $t('appDetailProvider') }}
                  </div>
                  <v-icon size="20" class="mb-3">mdi-account</v-icon>
                  <div class="d-flex align-center justify-center">
                    <div class="text-body-1 font-weight-bold">{{ app.detail.provider }}</div>
                    <v-icon color="green" size="16" class="ml-1 mb-1" v-if="app.detail.authenticated === 'true'">mdi-check-circle</v-icon>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-divider vertical/>

            <v-col cols="auto" class="mx-2">
              <v-card flat class="text-center" min-width="120">
                <v-card-text>
                  <div class="text-body-2 mb-3">
                    {{ $t('appDetailLanguage') }}
                  </div>
                  <v-icon size="20" class="mb-3">mdi-translate</v-icon>
                  <div class="text-body-1 font-weight-bold">{{ app.detail.language }}</div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-divider vertical/>
          </v-row>
        </div>
      <v-divider class="mx-2 my-4"/>

      <v-container 
        v-if="hasScreenshots" 
        fluid 
        :class="{
          'ma-0': true,
          'pa-0': !hasScreenshots
        }"
      >
        <v-row no-gutters class="overflow-x-auto flex-nowrap">
          <v-col v-for="(screenshot, index) in app.detail.screenshots" :key="index" cols="auto" class="mr-2">
            <v-img
              :src="screenshot"
              :aspect-ratio="9/18"
              width="200"
              cover
              style="background-color: var(--gray);"
              :style="{
                borderRadius: '16px',
                overflow: 'hidden',
              }"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="text-left" fluid>
        <p style="white-space: pre-line;line-height: 1.8;">{{ app.detail.description }}</p>
      </v-container>

      <v-divider style="margin-bottom: 60px !important;"></v-divider>

      <AHMView :show="AHMView.show" @close="AHMView.show = false">

        <template v-slot:header>
          <div class="d-flex align-center">
            <v-img
              :src="require('@/assets/ic_rolling.gif')"
              style="width: 20px; height: 20px; max-width: 20px; max-height: 20px;"
              class="mr-2"
              contain
            />
            <h4 class="mb-0">{{ $t('ahmvTitle') }}</h4>
          </div>
        </template>

        <template v-slot:body>

          <template v-if="AHMView.platform === 'mobile_chrome'">
            <!-- mobile_chrome 템플릿 -->
            <p v-html="$t('ahmvMobileChrome1', { url: AHMView.url, name: AHMView.name })"></p>
            <p v-html="$t('ahmvMobileChrome2')"></p>
            <p v-html="$t('ahmvMobileChrome3')"></p>
            <p v-html="$t('ahmvMobileChrome4')"></p>
          </template>

          <template v-else-if="AHMView.platform === 'mobile_safari'">
            <!-- mobile_safari 템플릿 -->
            <p v-html="$t('ahmvMobileSafari1', { url: AHMView.url, name: AHMView.name })"></p>
            <p style="display: inline; vertical-align: middle;">
              <span v-html="$t('ahmvMobileSafari2Part1')"></span>
              <v-img :src="require('@/assets/ic_ahmv_safari_share.svg')" max-width="20" max-height="20" contain class="mx-1 mb-1" style="vertical-align: middle; display: inline-block;" />
              <span v-html="$t('ahmvMobileSafari2Part2')"></span>
            </p>
            <p class="mt-4" v-html="$t('ahmvMobileSafari3')"></p>
            <p v-html="$t('ahmvMobileSafari4')"></p>
          </template>
          
          <template v-else-if="AHMView.platform === 'mobile_other'">
            <!-- mobile_other 템플릿 -->
            <p v-html="$t('ahmvMobileOther1', { url: AHMView.url, name: AHMView.name })"></p>
            <p v-html="$t('ahmvMobileOther2')"></p>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>{{ $t('ahmvMobileOther3') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div style="max-height: 250px; overflow-y: auto;">
                      <p v-html="$t('pwaMobileTitle')" class="text-h6 font-weight-bold mb-1"></p>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaSamsungAndroid')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaSamsungAndroid1')" class="mt-2"></p>
                        <p v-html="$t('pwaSamsungAndroid2')" class="mt-1"></p>
                        <p v-html="$t('pwaSamsungAndroid3')" class="mt-1"></p>
                        <p v-html="$t('pwaSamsungAndroid4')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaFirefoxAndroid')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaFirefoxAndroid1')" class="mt-2"></p>
                        <p v-html="$t('pwaFirefoxAndroid2')" class="mt-1"></p>
                        <p v-html="$t('pwaFirefoxAndroid3')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaEdgeAndroid')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaEdgeAndroid1')" class="mt-2"></p>
                        <p v-html="$t('pwaEdgeAndroid2')" class="mt-1"></p>
                        <p v-html="$t('pwaEdgeAndroid3')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaFirefoxIOS')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaFirefoxIOS1')" class="mt-2"></p>
                        <p v-html="$t('pwaFirefoxIOS2')" class="mt-1"></p>
                        <p v-html="$t('pwaFirefoxIOS3')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaEdgeIOS')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaEdgeIOS1')" class="mt-2"></p>
                        <p v-html="$t('pwaEdgeIOS2')" class="mt-1"></p>
                        <p v-html="$t('pwaEdgeIOS3')" class="mt-1 mb-0"></p>
                      </v-container>
                      <p v-html="$t('pwaDesktopTitle')" class="text-h6 font-weight-bold mt-3 mb-1"></p>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaEdgeDesktop')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaEdgeDesktop1')" class="mt-2"></p>
                        <p v-html="$t('pwaEdgeDesktop2')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaSafariMacOS')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaSafariMacOS1')" class="mt-2"></p>
                        <p v-html="$t('pwaSafariMacOS2')" class="mt-1"></p>
                        <p v-html="$t('pwaSafariMacOS3')" class="mt-1 mb-0"></p>
                      </v-container>

                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
          
          <template v-else-if="AHMView.platform === 'desktop_chrome'">
            <!-- desktop_chrome 템플릿 -->
            <p v-html="$t('ahmvDesktopChrome1', { url: AHMView.url, name: AHMView.name })"></p>
            <p style="display: inline; vertical-align: middle;">
              <span v-html="$t('ahmvDesktopChrome2Part1')"></span>
              <v-img :src="require('@/assets/ic_ahmv_chrome_download.svg')" max-width="20" max-height="20" contain class="mx-1 mb-1" style="vertical-align: middle; display: inline-block;" />
              <span v-html="$t('ahmvDesktopChrome2Part2')"></span>
            </p>
            <p class="mt-4" v-html="$t('ahmvDesktopChrome3')"></p>
          </template>
          
          <template v-else-if="AHMView.platform === 'desktop_safari'">
            <!-- desktop_safari 템플릿 -->
            <p v-html="$t('ahmvDesktopSafari1', { url: AHMView.url, name: AHMView.name })"></p>
            <p style="display: inline; vertical-align: middle;">
              <span v-html="$t('ahmvDesktopSafari2Part1')"></span>
              <v-img :src="require('@/assets/ic_ahmv_safari_share.svg')" max-width="20" max-height="20" contain class="mx-1 mb-1" style="vertical-align: middle; display: inline-block;" />
              <span v-html="$t('ahmvDesktopSafari2Part2')"></span>
            </p>
            <p class="mt-4" v-html="$t('ahmvDesktopSafari3')"></p>
            <p v-html="$t('ahmvDesktopSafari4')"></p>
          </template>
          
          <template v-else>
            <!-- desktop_other 템플릿 -->
            <p v-html="$t('ahmvDesktopOther1', { url: AHMView.url, name: AHMView.name })"></p>
            <p v-html="$t('ahmvDesktopOther2')"></p>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>{{ $t('ahmvDesktopOther3') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div style="max-height: 250px; overflow-y: auto;">
                      <p v-html="$t('pwaMobileTitle')" class="text-h6 font-weight-bold mb-1"></p>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaSamsungAndroid')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaSamsungAndroid1')" class="mt-2"></p>
                        <p v-html="$t('pwaSamsungAndroid2')" class="mt-1"></p>
                        <p v-html="$t('pwaSamsungAndroid3')" class="mt-1"></p>
                        <p v-html="$t('pwaSamsungAndroid4')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaFirefoxAndroid')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaFirefoxAndroid1')" class="mt-2"></p>
                        <p v-html="$t('pwaFirefoxAndroid2')" class="mt-1"></p>
                        <p v-html="$t('pwaFirefoxAndroid3')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaEdgeAndroid')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaEdgeAndroid1')" class="mt-2"></p>
                        <p v-html="$t('pwaEdgeAndroid2')" class="mt-1"></p>
                        <p v-html="$t('pwaEdgeAndroid3')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaFirefoxIOS')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaFirefoxIOS1')" class="mt-2"></p>
                        <p v-html="$t('pwaFirefoxIOS2')" class="mt-1"></p>
                        <p v-html="$t('pwaFirefoxIOS3')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaEdgeIOS')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaEdgeIOS1')" class="mt-2"></p>
                        <p v-html="$t('pwaEdgeIOS2')" class="mt-1"></p>
                        <p v-html="$t('pwaEdgeIOS3')" class="mt-1 mb-0"></p>
                      </v-container>
                      <p v-html="$t('pwaDesktopTitle')" class="text-h6 font-weight-bold mt-3 mb-1"></p>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaEdgeDesktop')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaEdgeDesktop1')" class="mt-2"></p>
                        <p v-html="$t('pwaEdgeDesktop2')" class="mt-1 mb-0"></p>
                      </v-container>
                      <v-divider></v-divider>
                      <v-container>
                        <p v-html="$t('pwaSafariMacOS')" class="font-weight-bold"></p>
                        <p v-html="$t('pwaSafariMacOS1')" class="mt-2"></p>
                        <p v-html="$t('pwaSafariMacOS2')" class="mt-1"></p>
                        <p v-html="$t('pwaSafariMacOS3')" class="mt-1 mb-0"></p>
                      </v-container>

                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>

        </template>

      </AHMView>

      <v-snackbar 
        :min-width="0" 
        :style="{ marginBottom: '60px' }" 
        v-model="snackbar.visibility"
      >
        <span style="white-space: normal; word-break: break-word;">
          {{ snackbar.text }}
        </span>
      </v-snackbar>

    </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationView from './elements/NavigationView.vue'
import AHMView from './AHMView.vue'
import functions from '@/rest/functions';

export default {
  name: "AppDetailView",
  components: {
    NavigationView,
    AHMView
  },
  data() {
    return {
      AHMView: {
        show: false,
        url: '',
        name: '',
        platform: '',
      },
      profile: {
        profileImage: require('@/assets/ic_tab_profile.svg'),
      },
      app: {
        aid: null,
        detail: [],
      },
      snackbar: {
        text: "",
        visibility: false,
      },
      functions: functions,
    }
  },
  computed: {
    hasScreenshots() {
      return this.app.detail.screenshots && this.app.detail.screenshots.length > 0;
    }
  },
  methods: {
    appsDetail() {
      this.$store.dispatch('appsDetail', {
        aid: this.aid
      })
      .then((result) => {
        if (result.success) {
          this.app.detail = this.$store.getters.getAppsDetail;
        }
        else {
          console.log("appsDetail error occured.")
        }
      });
    },
    appDetailBtnCkd(appId) {
    this.$router.push({ path: '/app-detail', query: { id: appId } });
    },
    appReport(id) {
      this.$router.push({ path: '/app-report', query: { id: id } });
    },
    appAuthenticate(id) {
      if(this.$store.getters.isLoggedIn) {
        this.$router.push({ path: '/app-authenticate', query: { id: id } });
      }
      else {
        this.$router.push('/login');
      }
    },
    myBtnCkd() {
      if(this.$store.getters.isLoggedIn) {
        this.$router.push('/me');
      }
      else {
        this.$router.push('/login');
      }
    },
    appAddBtnCkd(url, name) {
      this.AHMView.url = url;
      this.AHMView.name = name;
      this.AHMView.show = true;
    },
    appLibraryAddBtnCkd(id, url, icon, name, subname) {
      this.$store.dispatch('libraryAdd', {
        isLoggedIn: this.$store.getters.isLoggedIn,
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        id: id,
        url: url,
        icon: icon,
        name: name,
        subname: subname
      })
      .then((result) => {
        if(result.success) {
          this.snackbar.text = this.$t('libraryAdded');
          this.snackbar.visibility = true;
          this.appUpdateInteractions(id);
        }
        else {
          switch (result.code) {
            case '409':
              this.snackbar.text = this.$t('libraryAlreadyExist');
              this.snackbar.visibility = true;
              break;
            default:
              this.snackbar.text = this.$t('serverError');
              this.snackbar.visibility = true;
              break;
          }
        }
      })
    },
    backBtnCkd() {
      this.$router.go(-1);
    },
    appsUpdateViews() {
      this.$store.dispatch('appsUpdateViews', {
        aid: this.aid
      })
      .then((result) => {
        if (result.success) {
          console.log("appsUpdateViews success")
        }
        else {
          console.log("appsUpdateViews error occured.")
        }
      });
    },
    appUpdateInteractions(id) {
      this.$store.dispatch('appsUpdateInteractions', {
        aid: id,
      })
      .then((result) => {
        if(!result.success) {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
          return;
        }
      })
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if(this.$route.query.id) {
      this.aid = this.$route.query.id;
    }
    else {
      this.backBtnCkd();
    }
    this.AHMView.platform = functions.getPlatform();
    this.profile.profileImage = this.$store.getters.getProfileImage || require('@/assets/ic_tab_profile.svg');
    this.appsDetail();
    this.appsUpdateViews()
  },
  metaInfo() {
    return {
      title: `Aeida: ${this.app.detail.name}`,
      meta: [
        { name: 'description', content: this.app.detail.description },
        { name: 'keywords', content: this.app.detail.keywords },
        // Open Graph
        { property: 'og:title', content: `Aeida: ${this.app.detail.name}` },
        { property: 'og:description', content: this.app.detail.description },
        { property: 'og:image', content: this.app.detail.icon },
        { property: 'og:url', content: window.location.href },
        { property: 'og:type', content: 'website' },
        // Twitter Card
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: `Aeida: ${this.app.detail.name}` },
        { name: 'twitter:description', content: this.app.detail.description },
        { name: 'twitter:image', content: this.app.detail.icon },
      ]
    }
  },
};
</script>

<style scoped>

.add-home-btn {
  color: white !important;
  background-color: var(--theme) !important;
}

.overflow-x-auto {
  overflow-x: auto;
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.overflow-x-auto::-webkit-scrollbar {
  display: none;  /* WebKit */
}
.scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

</style>