import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../components/LoginView.vue'
import RegisterView from '../components/RegisterView.vue'
import MainView from '../components/MainView.vue'
import AppDetailView from '../components/AppDetailView.vue'
import AllAppsView from '../components/AllAppsView.vue'
import SearchView from '../components/SearchView.vue'
import MeView from '../components/MeView.vue'
import DeveloperView from '../components/DeveloperView.vue'
import NewAppView from '../components/NewAppView.vue'
import MyAppView from '../components/MyAppView.vue'
import AccountView from '../components/AccountView.vue'
import LibraryView from '../components/LibraryView.vue'
import DeleteAccountView from '../components/DeleteAccountView.vue'
import AppGuidelinesView from '../components/AppGuidelinesView.vue'
import HostedView from '../components/HostedView.vue'
import AppReportView from '../components/AppReportView.vue'
import AppAunticateView from '@/components/AppAunticateView.vue'
import TermsView from '@/components/TermsView.vue'
import DeveloperRegisterView from '@/components/DeveloperRegisterView.vue'
import DeveloperRegisterCompleteView from '@/components/DeveloperRegisterCompleteView.vue'
import PWAView from '@/components/PWAView.vue'
import InquiryView from '@/components/InquiryView.vue'
import ModifyAppView from '@/components/ModifyAppView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'MainView',
    component: MainView
  },
  {
    path: '/app-detail',
    name: 'AppDetailView',
    component: AppDetailView
  },
  {
    path: '/all',
    name: 'AllAppsView',
    component: AllAppsView
  },
  {
    path: '/search',
    name: 'SearchView',
    component: SearchView
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/register',
    name: 'RegisterView',
    component: RegisterView
  },
  {
    path: '/me',
    name: 'MeView',
    component: MeView
  },
  {
    path: '/developer',
    name: 'DeveloperView',
    component: DeveloperView
  },
  {
    path: '/developer-register',
    name: 'DeveloperRegisterView',
    component: DeveloperRegisterView
  },
  {
    path: '/developer-register-complete',
    name: 'DeveloperRegisterCompleteView',
    component: DeveloperRegisterCompleteView
  },
  {
    path: '/new-app',
    name: 'NewAppView',
    component: NewAppView
  },
  {
    path: '/modify-app',
    name: 'ModifyAppView',
    component: ModifyAppView
  },
  {
    path: '/my-app',
    name: 'MyAppView',
    component: MyAppView
  },
  {
    path: '/account',
    name: 'AccountView',
    component: AccountView
  },
  {
    path: '/library',
    name: 'LibraryView',
    component: LibraryView
  },
  {
    path: '/delete-account',
    name: 'DeleteAccountView',
    component: DeleteAccountView
  },
  {
    path: '/app-guidelines',
    name: 'AppGuidelinesView',
    component: AppGuidelinesView
  },
  {
    path: '/hosted',
    name: 'HostedView',
    component: HostedView,
    meta: { hideFooter: true }
  },
  {
    path: '/app-report',
    name: 'AppReportView',
    component: AppReportView
  },
  {
    path: '/app-authenticate',
    name: 'AppAunticateView',
    component: AppAunticateView
  },
  {
    path: '/inquiry',
    name: 'InquiryView',
    component: InquiryView
  },
  {
    path: '/terms',
    name: 'TermsView',
    component: TermsView
  },
  {
    path: '/pwa',
    name: 'PWAView',
    component: PWAView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { top: 0 }
  }
})

export default router
