import api from '../../rest/api';

export default {
    actions: {
        async authenticateCheckTxt( _, { url, uniqueToken }) {
            try {
                const response = await api.authenticateChcekTxt(url, uniqueToken);
                const code = response.data.code;
                if(code == "200") {
                    return { success: true };
                }
                else {
                    return { success: false, code: code};
                }
            } catch (error) {
            console.error('Failed:', error);
            return { success: false, code: "500"};
            }
        },
        async authenticateTransfer( _, { accessToken, uid, aid }) {
            try {
                const response = await api.authenticateTransfer(accessToken, uid, aid);
                const code = response.data.code;
                if(code == "200") {
                    return { success: true };
                }
                else {
                    return { success: false, code: code};
                }
            } catch (error) {
            console.error('Failed:', error);
            return { success: false, code: "500"};
            }
        },
    }
};