<template>
    <div>
      <v-app-bar dense color="white" flat>
        <router-link to="/" class="text-decoration-none">
          <v-toolbar-title class="aeida-title font-weight-bold black--text">Aeida</v-toolbar-title>
        </router-link>
        <v-spacer></v-spacer>
        
        <v-btn icon small class="mr-5" width="24" height="24" v-show="$vuetify.breakpoint.mdAndUp" to="/">
          <v-img :src="require('@/assets/ic_tab_home.svg')" width="24" height="24"/>
        </v-btn>
        <v-btn icon small class="mr-5" width="24" height="24" v-show="$vuetify.breakpoint.mdAndUp" to="/all">
          <v-img :src="require('@/assets/ic_tab_all.svg')" width="24" height="24"/>
        </v-btn>
        <v-btn icon small class="mr-5" width="24" height="24" v-show="$vuetify.breakpoint.mdAndUp" to="/search">
          <v-img :src="require('@/assets/ic_tab_search.svg')" width="24" height="24"/>
        </v-btn>
        <v-btn icon small class="mr-0" width="24" height="24" v-show="$vuetify.breakpoint.mdAndUp" @click="myBtnCkd">
          <v-img :src="profileImage" width="24" height="24"/>
        </v-btn>
      </v-app-bar>
  
      <v-bottom-navigation
        v-show="$vuetify.breakpoint.smAndDown"
        grow
        color="white"
        app
        fixed
        :height="isPWAIOS ? 70 : 56"
        >
        <v-btn icon class="pa-0" style="height: 100%;" to="/">
          <v-img 
            :src="require('@/assets/ic_tab_home.svg')" 
            width="24" 
            height="24"
            contain
            :class="['d-flex align-center justify-center', {'mb-4': isPWAIOS}]"
          />
        </v-btn>
        <v-btn icon class="pa-0" style="height: 100%;" to="/all">
          <v-img 
            :src="require('@/assets/ic_tab_all.svg')" 
            width="24" 
            height="24"
            contain
            :class="['d-flex align-center justify-center', {'mb-4': isPWAIOS}]"
          />
        </v-btn>
        <v-btn icon class="pa-0" style="height: 100%;" to="/search">
          <v-img 
            :src="require('@/assets/ic_tab_search.svg')" 
            width="24" 
            height="24"
            contain
            :class="['d-flex align-center justify-center', {'mb-4': isPWAIOS}]"
          />
        </v-btn>
        <v-btn icon class="pa-0" style="height: 100%;" @click="myBtnCkd">
          <v-img 
            :src="profileImage"
            width="24" 
            height="24"
            contain
            :class="['d-flex align-center justify-center', {'mb-4': isPWAIOS}]"
          />
        </v-btn>
      </v-bottom-navigation>
  </div>
</template>

<script>
import functions from '@/rest/functions';
export default {
  name: 'NavigationView',
  props: {
    profileImage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isPWAIOS: false
    }
  },
  methods: {
    myBtnCkd() {
      this.$emit('my-btn-clicked')
    },
    checkPwaIos() {
      this.isPWAIOS = functions.isPWA() && functions.isIOS();
    }
  },
  mounted() {
    this.checkPwaIos();
  }
}
</script>