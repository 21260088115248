<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1280px;">

      <navigation-view
        :profile-image="profile.profileImage"
        @my-btn-clicked="myBtnCkd"
      />

        <v-container fluid>
          <p class="text-h6 mb-2">{{ $t('guidelinesTitle') }}</p>
        </v-container>

        <v-container class="mt-2 pa-10" style="background-color: var(--gray);" fluid>
          <p class="ma-0 text-center" style="line-height: 1.8;">
            {{ $t('guidelines_asd') }}
          </p>
        </v-container>

        <v-container class="mt-6 mb-10" fluid>
          <!-- 1 -->
          <p class="text-h6 mb-2">{{ $t('guidelines_zcz') }}</p>
          <p style="line-height: 2.4;" v-html="$t('guidelines_afk')">
          </p>
          <!-- 2 -->
          <p class="text-h6 mb-2">{{ $t('guidelines_zcl') }}</p>
          <p class="mb-2" style="line-height: 1.8;">
            {{ $t('guidelines_mmm') }}
          </p>
          <p style="line-height: 2.4;" v-html="$t('guidelines_all')"></p>
          <!-- 3 -->
          <p class="text-h6 mb-2">{{ $t('guidelines_alz') }}</p>
          <p class="mb-2" style="line-height: 1.8;">
            {{ $t('guidelines_lam') }}
          </p>
          <p style="line-height: 2.4;" v-html="$t('guidelines_las')"></p>
          <!-- 4 -->
          <p class="text-h6 mb-2">{{ $t('guidelines_lzc') }}</p>
          <p style="line-height: 2.4;" v-html="$t('guidelines_zlc')"></p>
        </v-container>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationView from './elements/NavigationView.vue'
export default {
  name: 'AppGuidelinesView',
  data() {
    return {
      profile: {
        name: '',
        profileImage: require('@/assets/ic_tab_profile.svg'),
      }
    };
  },
  components: {
    NavigationView
  },
  methods: {
    myBtnCkd() {
      if(this.$store.getters.isLoggedIn) {
        this.$router.push('/me');
      }
      else {
        this.$router.push('/login');
      }
    },
    backBtnCkd() {
      this.$router.go(-1);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.profile.profileImage = this.$store.getters.getProfileImage || require('@/assets/ic_tab_profile.svg');
  },
};
</script>

<style scoped>

</style>