<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1280px;">

      <navigation-view
        :profile-image="profile.profileImage"
        @my-btn-clicked="myBtnCkd"
      />

        <v-container fluid>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-row no-gutters align="center" justify="space-between" style="width: 100%;">
                  <v-col cols="auto">
                    <span class="caption font-weight-bold">이용약관</span>
                  </v-col>
                  <v-col cols="auto">
                    <span class="text-right mr-2 dark-gray--text" style="font-size:12px;">2024.10.17</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                contents
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>

        <v-container fluid>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-row no-gutters align="center" justify="space-between" style="width: 100%;">
                  <v-col cols="auto">
                    <span class="caption font-weight-bold">개인정보처리방침</span>
                  </v-col>
                  <v-col cols="auto">
                    <span class="text-right mr-2 dark-gray--text" style="font-size:12px;">2024.10.17</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                contents
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>

        <v-container fluid>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-row no-gutters align="center" justify="space-between" style="width: 100%;">
                  <v-col cols="auto">
                    <span class="caption font-weight-bold">환불약관</span>
                  </v-col>
                  <v-col cols="auto">
                    <span class="text-right mr-2 dark-gray--text" style="font-size:12px;">2024.10.17</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                contents
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationView from './elements/NavigationView.vue'
export default {
  name: 'TermsView',
  data() {
    return {
      profile: {
        name: '',
        profileImage: require('@/assets/ic_tab_profile.svg'),
      }
    };
  },
  components: {
    NavigationView,
  },
  methods: {
    myBtnCkd() {
      if(this.$store.getters.isLoggedIn) {
        this.$router.push('/me');
      }
      else {
        this.$router.push('/login');
      }
    },
    backBtnCkd() {
      this.$router.go(-1);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.profile.profileImage = this.$store.getters.getProfileImage || require('@/assets/ic_tab_profile.svg');
  },
};
</script>

<style scoped>

</style>