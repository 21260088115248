import Vue from 'vue';
import VueMeta from 'vue-meta'
import App from './App.vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

import router from './router';
import store from './store';

import VueI18n from 'vue-i18n';
import en from './locale/en';
import ko from './locale/ko';

import fcmIcon from '@/assets/ic_fcm.png'
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import { AnalyticsFunctions } from './rest/analytics/functions.js';

/* fcm */
const firebaseConfig = {
  apiKey: "AIzaSyATIa36coA95tFeNxovPOw6-8F3II7jjJE",
  authDomain: "aeida-5854b.firebaseapp.com",
  projectId: "aeida-5854b",
  storageBucket: "aeida-5854b.appspot.com",
  messagingSenderId: "650284324999",
  appId: "1:650284324999:web:f9e892a8a2e44e2d7dc022",
  measurementId: "G-016VQ5MRF3"
};
const firebaseApp = initializeApp(firebaseConfig);
let messaging;
try {
  messaging = getMessaging(firebaseApp);
  Vue.prototype.$messaging = messaging;
  onMessage(messaging, (payload) => {
    if (document.visibilityState === 'visible') {
      if (Notification.permission === 'granted') {
        const notificationTitle = payload.data.title;
        const notificationOptions = {
          body: payload.data.body,
          icon: fcmIcon
        };
        new Notification(notificationTitle, notificationOptions);
      } else {
        console.log('Notification permission not granted');
      }
    }
  });
} catch (err) {
    console.error('Failed to initialize Firebase Messaging', err);
}

/* analytics */
AnalyticsFunctions.initAnalytics(firebaseApp);

/* vue */
Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(Vuetify);
Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'ko',
  messages: {
    en: en,
    ko: ko
  },
});

const vuetify = new Vuetify({
});

new Vue({
  store,
  vuetify: vuetify,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');