<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1280px;">

      <navigation-view
        :profile-image="require('@/assets/ic_tab_profile.svg')"
      />

      <v-container class="my-3 pa-0" fluid>
        <v-btn 
          @click="backBtnCkd" 
          text
          color="black"
        >
          <v-icon class="back-icon">mdi-chevron-left</v-icon>
          <span> {{ $t('back') }} </span>
        </v-btn>
      </v-container>

      <v-col cols="12" class="text-center mt-10">

        <h2 class="black--text font-weight-bold aeida-title-light mb-8">Welcome to Aeida.</h2>

        <v-text-field
          v-model="email"
          :placeholder="$t('registerEmail')" 
          hide-details
          style="background-color: var(--gray);"
          rounded 
          class="ipt mb-5 py-2"/>

          <v-text-field
          v-model="pw"
          :placeholder="$t('registerPassword')"
          hide-details
          style="background-color: var(--gray);"
          rounded 
          type="Password" 
          color="gray" 
          class="ipt mb-5 py-2"/>

          <v-text-field
          v-model="rePW"
          :placeholder="$t('registerRePassword')"
          hide-details
          style="background-color: var(--gray);"
          rounded 
          type="password" 
          color="gray" 
          class="ipt mb-5 py-2"/>

          <v-text-field
          v-model="name"
          :placeholder="$t('registerName')"
          hide-details
          style="background-color: var(--gray);"
          rounded
          color="gray" 
          class="ipt mb-5 py-2"/>

          <v-col cols="12">
            <v-row align="center" justify="end" class="mb-5">
              <v-checkbox
                v-model="termsAccepted"
                color="var(--theme)"
                hide-details
                class="custom-checkbox mr-2"
              >
                <template v-slot:label>
                  <span class="black--text">{{ $t('registerTerms') }}</span>
                </template>
              </v-checkbox>
            </v-row>
          </v-col>

        <v-btn
          color="var(--theme)"
          block 
          rounded 
          large
          v-on:click="completeBtnCkd"
          class="white--text font-weight-bold mb-5">
          {{$t('registerRegister')}}
        </v-btn>

        <v-btn to="/register" class="font-weight-bold theme--text" text>
          {{$t('registerLogin')}}
        </v-btn>

      </v-col>

      <v-snackbar 
        :min-width="0" 
        :style="{ marginBottom: '60px' }" 
        v-model="snackbar.visibility"
      >
        <span style="white-space: normal; word-break: break-word;">
          {{ snackbar.text }}
        </span>
      </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationView from './elements/NavigationView.vue'
import functions from '../rest/functions';
import { AnalyticsFunctions } from '../rest/analytics/functions.js';
import { AnalyticsParams } from '../rest/analytics/params.js';

export default {
  name: 'RegisterView',
  data() {
    return {
      email: "",
      pw: "",
      rePW: "",
      name: "",
      termsAccepted: false,
      snackbar: {
        text: "",
        visibility: false,
      }
    }
  },
  components: {
    NavigationView,
  },
  methods: {
    async completeBtnCkd() {
      window.scrollTo(0, 0);
      if (this.email === "") {
        this.snackbar.text = this.$t('userEmailEmpty');
        this.snackbar.visibility = true;
        return;
      }
      if (!functions.isValidEmail(this.email)) {
        this.snackbar.text = this.$t('userEmailInvalid');
        this.snackbar.visibility = true;
        return;
      }
      if (this.pw === "" || this.rePW === "") {
        this.snackbar.text = this.$t('userPWEmpty');
        this.snackbar.visibility = true;
        return;
      }
      if (this.pw !== this.rePW) {
        this.snackbar.text = this.$t('userPWUnmatched');
        this.snackbar.visibility = true;
        return;
      }
      if (!this.termsAccepted) {
        this.snackbar.text = this.$t('userTermsRequired');
        this.snackbar.visibility = true;
        return;
      }
      this.$store.dispatch('userRegister', {
        email: this.email,
        pw: this.pw,
        name: this.name
      })
      .then(result => {
        if (result.success) {
          AnalyticsFunctions.logging(AnalyticsParams.REGISTER);
          this.$router.push('/');
        } else {
          switch (result.code) {
            case '409':
              this.snackbar.text = this.$t('userExist');
              break;
            default:
              this.snackbar.text = this.$t('serverError');
              break;
          }
          this.snackbar.visibility = true;
        }
      })
      .catch(error => {
        console.error('Registration error:', error);
        this.snackbar.text = this.$t('serverError');
        this.snackbar.visibility = true;
      });
    },
    backBtnCkd() {
      this.$router.go(-1);
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    // if(this.$store.getters.isLoggedIn) {
    //   this.$router.push('/');
    //   return;
    // }
  }
}
</script>

<style scoped>
</style>