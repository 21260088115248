export default {
  isPWA() {
    return window.matchMedia('(display-mode: standalone)').matches || 
      window.navigator.standalone || 
      document.referrer.includes('android-app://');
  },
  isIOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  },
  getOS() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const platform = window.navigator.platform.toLowerCase();
    if (/android/.test(userAgent)) {
      return 'android';
    }
    if (/ipad|iphone|ipod/.test(userAgent) && !window.MSStream) {
      return 'ios';
    }
    const isChrome = /chrome/.test(userAgent) && /google inc/.test(navigator.vendor);
    if (isChrome && !/mobile|android|ipad|iphone|ipod/.test(userAgent)) {
      return 'desktop';
    }
    if (/win/.test(platform) || /mac/.test(platform) || /linux/.test(platform)) {
      return 'desktop';
    }
    return 'other';
  },
  getPlatform() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const platform = window.navigator.platform.toLowerCase();
    let isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    // 모바일 체크
    if (isMobile) {
      // iOS 체크
      if (/iphone|ipad|ipod/.test(userAgent)) {
        if (userAgent.includes('crios') || userAgent.includes('chrome')) {
          return 'mobile_chrome';
        } else {
          return 'mobile_safari';
        }
      }
      // Android 체크
      else if (userAgent.includes('android')) {
        if (userAgent.includes('chrome')) {
          return 'mobile_chrome';
        } else {
          return 'mobile_other';
        }
      }
      // 기타 모바일
      else {
        return 'mobile_other';
      }
    }
    // 데스크톱 체크
    else {
      // Mac 체크
      if (platform.includes('mac')) {
        if (userAgent.includes('safari') && !userAgent.includes('chrome')) {
          return 'desktop_safari';
        } else if (userAgent.includes('chrome')) {
          return 'desktop_chrome';
        } else {
          return 'desktop_other';
        }
      }
      // Windows 체크
      else if (platform.includes('win')) {
        if (userAgent.includes('chrome')) {
          return 'desktop_chrome';
        } else {
          return 'desktop_other';
        }
      }
      // 기타 데스크톱
      else {
        return 'desktop_other';
      }
    }
  },
  isValidEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  },
  isValidUrl(string) {
    try {
        new URL(string);
        return true;
    } catch (_) {
        return false;  
    }
  },
  formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    } else if (num >= 100) {
      return Math.round(num / 100) * 100;
    } else if (num >= 50) {
      return Math.round(num / 50) * 50;
    } else if (num >= 10) {
      return Math.round(num / 10) * 10;
    }
    return 0;
  }
};