<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1280px;">

      <navigation-view
        :profile-image="profile.profileImage"
        @my-btn-clicked="myBtnCkd"
      />

        <v-container class="my-3 pa-0" fluid>
          <v-btn 
            @click="backBtnCkd" 
            text
            color="black"
          >
            <v-icon class="back-icon">mdi-chevron-left</v-icon>
            <span> {{ $t('developerRegisterTitle') }} </span>
          </v-btn>
        </v-container>

        <v-container class="mb-4" fluid>
          <p class="text-h5 mt-0 mb-5">{{ $t('developerRegisterSubtitle') }}</p>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header class="text-body-2">
                {{ $t('developerRegisterTextTitle') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pb-2">
                
                <v-container style="background-color: var(--gray);border-radius: 16px;" fluid>
                  <developer-terms></developer-terms>
                </v-container>

                <p class="text-body-2 mt-5">{{ $t('developerRegisterTextSubtitle') }}</p>

                <v-container class="pa-0" fluid>
                <v-btn 
                  class="pa-5 d-flex flex-column align-center" 
                  style="background-color: #F5F5FF; width: 100%; border-radius: 16px;" 
                  elevation="0"
                  href="/app-guidelines"
                  target="_blank"
                >
                  <span class="mr-2">{{ $t('newAppGuidelines') }}</span>
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-btn>
              </v-container>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>

        <v-col cols="12">
          <v-row align="center" justify="end">
            <v-checkbox
              v-model="termsAccepted"
              color="var(--theme)"
              hide-details
              class="custom-checkbox mr-2 ma-0 pa-0"
            >
              <template v-slot:label>
                <span class="black--text">{{ $t('developerTerms') }}</span>
              </template>
            </v-checkbox>
          </v-row>
        </v-col>

        <v-divider class="mx-2 my-5 mb-7"></v-divider>

        <v-container class="py-0" fluid>

        <p class="text-h6 mt-0">{{ $t('developerInformationTitle') }}</p>

        <v-text-field
          v-model="billing.name"
          :placeholder="$t('developerInformationPlaceholder1')" 
          hide-details
          style="max-width:400px !important;background-color: var(--gray);"
          rounded 
          class="ipt mb-5 py-2"/>

        <v-text-field
          v-model="billing.contact"
          :placeholder="$t('developerInformationPlaceholder2')" 
          hide-details
          style="max-width:400px !important;background-color: var(--gray);"
          rounded 
          color="gray" 
          class="ipt mb-5 py-2"/>

        <v-text-field
          v-model="billing.birth"
          :placeholder="$t('developerInformationPlaceholder3')" 
          hide-details
          style="max-width:400px !important;background-color: var(--gray);"
          rounded 
          class="ipt mb-5 py-2"/>

        <p class="text-h6 mt-0">{{ $t('developerBillingTitle') }}</p>

        <v-text-field
          v-model="billing.card"
          :placeholder="$t('developerBillingPlaceholder1')" 
          hide-details
          style="max-width:400px !important;background-color: var(--gray);"
          rounded 
          class="ipt mb-5 py-2"/>

        <v-text-field
          v-model="billing.expiryDate"
          :placeholder="$t('developerBillingPlaceholder2')" 
          hide-details
          style="max-width:400px !important;background-color: var(--gray);"
          rounded
          color="gray" 
          class="ipt mb-5 py-2"/>
        
        <v-text-field
          v-model="billing.pw"
          :placeholder="$t('developerBillingPlaceholder3')" 
          hide-details
          style="max-width:400px !important;background-color: var(--gray);"
          rounded
          color="gray" 
          class="ipt mb-5 py-2"/>

        </v-container>

        <v-divider class="mx-2 my-5 mt-7"></v-divider>

        <v-container class="py-0 mb-10" fluid>

          <p class="text-h6 mt-0">{{ $t('developerProductTitle') }}</p>

          <v-row align="center" justify="center" class="ma-0 mt-8 mb-5 py-8" style="background-color: var(--theme-light); border-radius: 16px;">
            <v-col class="pa-0" align="center">
              <img 
                class="app-icon app-icon-elevation"
                :src="require('@/assets/ic_developer_product.png')" 
              />
              <p class="text-body-1 mt-4 mb-0 pa-0">19,900₩ / {{ $t('developerProduct') }}</p>
            </v-col>
          </v-row>

          <v-btn
            color="var(--theme)"
            block 
            rounded 
            large
            v-on:click="completeBtnCkd"
            class="white--text font-weight-bold">
            {{$t('developerComplete')}}
          </v-btn>

        </v-container>

        <v-snackbar style="margin-bottom: 60px;" v-model="snackbar.visibility">
          <v-container class="text-center pd-0" style="padding: 0;">
            {{ snackbar.text }}
          </v-container>
          <template v-slot:actions>
            </template>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationView from './elements/NavigationView.vue'
import DeveloperTerms from './elements/developerTermsView.vue'

export default {
  name: 'DeveloperRegisterView',
  data() {
    return {
      termsAccepted: false,
      billing: {
        name: '',
        contact: '',
        birth: '',
        card: '',
        expiryDate: '',
        pw: ''
      },
      profile: {
        profileImage: require('@/assets/ic_tab_profile.svg'),
      },
      snackbar: {
        text: "",
        visibility: false,
      },
    };
  },
  components: {
    NavigationView,
    DeveloperTerms
  },
  methods: {
    developerRegister() {
      this.$store.dispatch('userRegisterDeveloper', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
      })
      .then((result) => {
        if (result.success) {
          this.$store.commit('setDeveloper', 'true');
          this.$router.push('/developer-register-complete')
        }
        else {
          switch (result.code) {
            case '409':
            this.$store.commit('setDeveloper', 'true');
              this.$router.push('/developer-register-complete')
              break;
            default:
              this.snackbar.text = this.$t('serverError');
              this.snackbar.visibility = true;
              break;
          }
        }
      });
    },
    myBtnCkd() {
      if(this.$store.getters.isLoggedIn) {
        this.$router.push('/me');
      }
      else {
        this.$router.push('/login');
      }
    },
    completeBtnCkd() {

      if(!this.termsAccepted) {
        this.snackbar.text = this.$t('developerRegisterTermsRequired');
        this.snackbar.visibility = true;
        return;
      }

      if(!/^[가-힣a-zA-Z]{2,20}$/.test(this.billing.name)) {
        this.snackbar.text = this.$t('developerNameInvalid');
        this.snackbar.visibility = true;
        return;
      }

      if(!/^01[016789]\d{7,8}$/.test(this.billing.contact)) {
        this.snackbar.text = this.$t('developerContactInvalid');
        this.snackbar.visibility = true;
        return;
      }

      if(!/^\d{6}$/.test(this.billing.birth)) {
        this.snackbar.text = this.$t('developerBirthInvalid');
        this.snackbar.visibility = true;
        return;
      }

      if(!/^\d{15,16}$/.test(this.billing.card)) {
        this.snackbar.text = this.$t('developerCardInvalid');
        this.snackbar.visibility = true;
        return;
      }

      if(!/^\d{4}$/.test(this.billing.expiryDate)) {
        this.snackbar.text = this.$t('developerExpiryDateInvalid');
        this.snackbar.visibility = true;
        return;
      }

      if(!/^\d{2}$/.test(this.billing.pw)) {
        this.snackbar.text = this.$t('developerPWDateInvalid');
        this.snackbar.visibility = true;
        return;
      }

      this.$store.dispatch('setLoading', true);
      this.$store.dispatch('billingPay', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        name: this.billing.name,
        contact: this.billing.contact,
        birth: this.billing.birth,
        card: this.billing.card,
        expiryDate: this.billing.expiryDate,
        pw: this.billing.pw,
      })
      .then((result) => {
        if (result.success) {
          this.developerRegister();
        }
        else {
          switch (result.code) {
            case '3002':
              this.snackbar.text = this.$t('billingResponse3002');
              break;
            case '3011':
              this.snackbar.text = this.$t('billingResponse3011');
              break;
            case '3021':
              this.snackbar.text = this.$t('billingResponse3021');
              break;
            case '3054':
              this.snackbar.text = this.$t('billingResponse3054');
              break;
            case '3098':
              this.snackbar.text = this.$t('billingResponse3098');
              break;
            case '9998':
              this.snackbar.text = this.$t('billingResponse9998');
              break;
            default:
              this.snackbar.text = `${this.$t('serverError')} code: ${result.code}`;
              break;
          }
          this.snackbar.visibility = true;
        }
      })
      .finally(() => {
        this.$store.dispatch('setLoading', false);
      });
    },
    backBtnCkd() {
      this.$router.go(-1);
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    if(!(this.$store.getters.isLoggedIn)) {
      this.$router.push('/');
      return;
    }
    this.profile.profileImage = this.$store.getters.getProfileImage;
  }
};
</script>

<style scoped>

</style>