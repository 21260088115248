import api from '../../rest/api';

const initialState = {
  refreshToken: null,
  accessToken: null,
  uid: null,
  name: null,
  profileImage: null,
  fcmToken: null,
  uniqueToken: null,
  deleted: null,
  developer: null,
};

export default {
  state: { ...initialState },
  mutations: {
    resetState(state) {
      Object.assign(state, initialState);
    },
    setRefreshToken(state, value) {
      state.refreshToken = value;
    },
    setAccessToken(state, value) {
      state.accessToken = value;
    },
    setUID(state, value) {
      state.uid = value;
    },
    setName(state, data) {
      state.name = data;
    },
    setProfileImage(state, data) {
      state.profileImage = data;
    },
    setFCMToken(state, data) {
      state.fcmToken = data;
    },
    setUniqueToken(state, data) {
      state.uniqueToken = data;
    },
    setDeleted(state, data) {
      state.deleted = data;
    },
    setDeveloper(state, data) {
      state.developer = data;
    },
  },
  getters: {
    getRefreshToken(state) {
      return state.refreshToken
    },
    getAccessToken(state) {
      return state.accessToken
    },
    getUID(state) {
      return state.uid
    },
    isLoggedIn(state) {
      return !!(state.uid && state.accessToken && state.refreshToken);
    },
    getName(state) {
      return state.name;
    },
    getProfileImage(state) {
      return state.profileImage;
    },
    getFCMToken(state) {
      return state.fcmToken;
    },
    getUniqueToken(state) {
      return state.uniqueToken;
    },
    getDeleted(state) {
      return state.deleted;
    },
    getDeveloper(state) {
      return state.developer;
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    async tokenRefresh({ commit }, { refreshToken, uid }) {
      try {
        const response = await api.tokenRefresh(refreshToken, uid);
        const code = response.data.code;
        if(code == "200") {
          const { refresh_token, access_token, uid } = response.data.data;
          commit('setRefreshToken', refresh_token);
          commit('setAccessToken', access_token);
          commit('setUID', uid);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed to token refresh:', error);
        return { success: false, code: "500"};
      }
    },
    async userLogin({ commit }, { email, pw }) {
      try {
        const response = await api.userLogin(email, pw);
        const code = response.data.code;
        if(code == "200") {
          const { refresh_token, access_token, uid } = response.data.data;
          commit('setRefreshToken', refresh_token);
          commit('setAccessToken', access_token);
          commit('setUID', uid);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed to user register:', error);
        return { success: false, code: "500"};
      }
    },
    async userRegister({ commit }, { email, pw, name }) {
      try {
        const response = await api.userRegister(email, pw, name);
        const code = response.data.code;
        if(code == "200") {
          const { refresh_token, access_token, uid } = response.data.data;
          commit('setRefreshToken', refresh_token);
          commit('setAccessToken', access_token);
          commit('setUID', uid);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
    async userRegisterDeveloper( _, { accessToken, uid }) {
      try {
        const response = await api.userRegisterDeveloper(accessToken, uid);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
    async userMe({ commit }, { accessToken, uid }) {
      try {
        const response = await api.userMe(accessToken, uid);
        const code = response.data.code;
        if(code == "200") {
          const { name, profile_image, fcm_token, unique_token, deleted, developer } = response.data.data;
          commit('setName', name);
          commit('setProfileImage', profile_image);
          commit('setFCMToken', fcm_token);
          commit('setUniqueToken', unique_token);
          commit('setDeleted', deleted);
          commit('setDeveloper', developer);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
  },
};