<template>
  <footer class="footer">
    <div class="footer-content">
      <p class="copyright">© 2024 octave. All rights reserved.</p>
      <p class="customer-service">{{ $t('footerCustomerService') }}</p>
      <router-link to="/terms" class="terms dark-gray--text">{{ $t('footerTerms') }}</router-link>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterView',
}
</script>

<style scoped>
.footer {
  background-color: var(--gray);
  padding: 10px 20px;
  width: 100%;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.copyright, .terms, .customer-service {
  margin: 5px 20px 5px 0;
  font-size: 12px;
}

.terms {
  text-decoration: none;
  color: inherit;
}

.terms:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .footer {
    margin-bottom: 55px !important;
  }
  
  .footer-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .copyright, .terms, .customer-service {
    margin: 5px 0;
    text-align: center;
  }
}
</style>