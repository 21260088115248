<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1280px;">

      <navigation-view
        :profile-image="profile.profileImage"
        @my-btn-clicked="myBtnCkd"
      />

        <v-container class="my-3 pa-0" fluid>
          <v-btn 
            @click="backBtnCkd" 
            text
            color="black"
          >
            <v-icon class="back-icon">mdi-chevron-left</v-icon>
            <span> {{ $t('deleteTitle') }} </span>
          </v-btn>
        </v-container>

        <v-container fluid>
          <p class="ma-0">{{ $t('deleteNowLoggedIn') }} <b>{{ profile.name }} </b></p>
          <v-divider class="my-5"/>
        </v-container>

        <v-col cols="12">
          <v-row align="center" justify="end" class="mb-1">
            <v-checkbox
              v-model="checkAccepted"
              color="var(--theme)"
              hide-details
              class="custom-checkbox mr-2 ma-0 pa-0"
            >
              <template v-slot:label>
                <span class="black--text">{{ $t('deleteChecked') }}</span>
              </template>
            </v-checkbox>
          </v-row>
        </v-col>

        <v-container fluid>
          <v-btn
            color="var(--red)"
            block 
            rounded 
            large
            v-on:click="deleteBtnCkd"
            class="white--text font-weight-bold">
            {{$t('deleteDelete')}}
          </v-btn>
        </v-container>

        <v-snackbar style="margin-bottom: 60px;" v-model="snackbar.visibility">
          <v-container class="text-center pd-0" style="padding: 0;">
            {{ snackbar.text }}
          </v-container>
          <template v-slot:actions>
            </template>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavigationView from './elements/NavigationView.vue'
export default {
  name: 'DeleteAccountView',
  data() {
    return {
      profile: {
        name: '',
        profileImage: require('@/assets/ic_tab_profile.svg'),
      },
      deleteTermsUrl: "https://oct4ve.notion.site/875b94bd27944569ba21ac1d491c2354?pvs=4",
      checkAccepted: false,
      snackbar: {
        text: "",
        visibility: false,
      },
    };
  },  
  components: {
    NavigationView
  },
  methods: {
    myBtnCkd() {
      if(this.$store.getters.isLoggedIn) {
        this.$router.push('/me');
      }
      else {
        this.$router.push('/login');
      }
    },
    deleteBtnCkd() {
      if (!this.checkAccepted) {
        this.snackbar.text = this.$t('deleteCheckRequired');
        this.snackbar.visibility = true;
        return;
      }
      // delete
    },
    backBtnCkd() {
      this.$router.go(-1);
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    if(!(this.$store.getters.isLoggedIn)) {
      this.$router.push('/');
      return;
    }
    this.profile.name = this.$store.getters.getName;
    this.profile.profileImage = this.$store.getters.getProfileImage || require('@/assets/ic_tab_profile.svg');
  }
};
</script>

<style scoped>

</style>