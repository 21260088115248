export default {
  
  mainLibrary: '라이브러리',
  mainLibraryManage: '관리',
  mainRecommends: '추천 앱',
  mainLibraryEmptyLoggedIn: '아직 라이브러리에 추가한 앱이 없습니다.',
  mainLibraryEmptyNotLoggedIn: 'Aeida에 로그인',
  mainLibraryEmptyNotLoggedInSuffix: '하고 관심있는 앱을 모아보세요.',
  mainAddApp: '추가 +',

  libraryManageTitle: '라이브러리 관리',

  allAppsTitle: '모든 앱',

  searchHint: "앱 검색",
  searchNoItems: "앱 검색 결과가 없습니다.",

  loginEmail: "이메일",
  loginPassword: "비밀번호",
  loginLogin: "로그인",
  loginRegister: "Aeida 회원가입",

  registerEmail: "이메일",
  registerPassword: "비밀번호",
  registerRePassword: "비밀번호 확인",
  registerName: "이름 또는 조직명",
  registerRegister: "회원가입",
  registerLogin: "로그인으로 돌아가기",
  registerTerms: "서비스 이용약관 및 개인정보 처리방침 동의",

  userInvalid: '로그인 정보가 일치하지 않습니다.',
  userNotExist: '존재하지 않는 회원입니다.',
  userExist: '이미 존재하는 회원입니다.',
  userBlocked: '계정이 차단되었습니다.',
  userDeleted: '계정이 삭제되었습니다.',
  userEmailEmpty: '이메일을 입력해주세요.',
  userEmailInvalid: '유효한 이메일을 입력해주세요.',
  userPWEmpty: '비밀번호를 입력해주세요.',
  userPWUnmatched: '비밀번호가 일치하지 않습니다.',
  userTermsRequired: '이용약관에 동의해주세요.',

  mainAppAdd: '추가 +',

  appDetailAddLibrary: '라이브러리에 추가 +',
  appDetailAddLibrarySubtitle: '◦ 이 앱은 Aeida에서 실행할 수 있습니다.',
  appDetailAddHome: '홈에 앱 추가 +',
  appDetailAddHomeSubtitle: '◦ 알림을 받으려면 앱을 홈 화면에 추가하세요.',
  appDetailAdded: '추가됨',
  appDetailProvider: '제공',
  appDetailLanguage: '언어',
  appDetailReportApp: '앱 신고',
  appDetailAuthenticateApp: '앱 소유자 인증',

  meAccount: '계정',
  meDeveloper: '개발자',
  meDeveloperRegister: '개발자 등록',
  meInquiry: 'Aeida 문의',

  developerRegisterTitle: '개발자 등록',
  developerRegisterSubtitle: 'Aeida 개발자 등록을 환영합니다.',
  developerRegisterTextTitle: 'Aeida 개발자 이용약관',
  developerRegisterTextSubtitle: '추가 리소스를 확인해보세요.',
  developerTerms: '개발자 이용약관 동의',
  developerInformationTitle: '구매자 정보 입력',
  developerInformationPlaceholder1: '이름',
  developerInformationPlaceholder2: '연락처 (- 제외)',
  developerInformationPlaceholder3: '생년월일 (YYMMDD)',
  developerBillingTitle: '결제 정보 입력',
  developerBillingPlaceholder1: '카드번호 (- 제외)',
  developerBillingPlaceholder2: '유효기간 (YYMM)',
  developerBillingPlaceholder3: '비밀번호 앞 두자리 (**)',
  developerProductTitle: '상품 확인',
  developerProduct: '영구 개발자 라이센스',
  developerComplete: '결제 및 등록',
  developerRegisterTermsRequired: '이용약관에 동의해주세요.',
  developerNameInvalid: '올바른 이름을 입력해주세요.',
  developerContactInvalid: '올바른 형식의 연락처를 입력해주세요.',
  developerBirthInvalid: '올바른 생년월일을 입력해주세요.',
  developerCardInvalid: '올바른 카드 번호를 입력해주세요.',
  developerExpiryDateInvalid: '올바른 유효기간을 입력해주세요.',
  developerPWDateInvalid: '올바른 비밀번호를 입력해주세요.',

  accountTitle: '계정',
  accountLogout: '로그아웃',
  accountDelete: '회원탈퇴',

  deleteTitle: '회원탈퇴',
  deleteNowLoggedIn: '현재 로그인 된 계정:',
  deleteChecked: '회원탈퇴 동의',
  deleteCheckRequired: '회원탈퇴 약관에 동의해주세요.',
  deleteDelete: '회원탈퇴',

  developerTitle: '개발자',
  developerNewApp: '신규 앱 등록',
  developerMyApp: '나의 앱',

  newAppTitle: '신규 앱 등록',
  newAppGuidelines: '앱 검토 지침',
  newAppUrl: '앱 URL',
  newAppProvider: '제공자:',
  newAppIcon: '아이콘',
  newAppIconSubtitle: '◦ 512x512px 권장',
  newAppName: '이름',
  newAppNameSubtitle: '◦ 최대 20자',
  newAppSubname: '부제',
  newAppSubnameSubtitle: '◦ 최대 30자',
  newAppDescription: '앱 설명',
  newAppDescriptionSubtitle: '◦ 최대 1000자',
  newAppDescriptionPlaceholder: '회원님의 앱에 대해 알려주세요.',
  newAppScreenshot: '앱 스크린샷',
  newAppScreenshotSubtitle: '◦ 최대 4장의 스크린샷 (1125x2436px 권장)',
  newAppCategory: '카테고리',
  newAppLanguage: '지원 언어',
  newAppKeyword: '키워드',
  newAppKeywordSubtitle: '◦ 쉼표(,)로 구분, 최대 10개',
  newAppComplete: '앱 제출',
  newappUrlInvalid: '유효한 URL을 입력해주세요.',
  newappIconEmpty: '아이콘 이미지를 선택해주세요.',
  newappNameEmpty: '이름을 입력해주세요.',
  newappNameTooLong: '이름을 20자 이하로 입력해주세요.',
  newappSubnameEmpty: '부제를 입력해주세요.',
  newappSubnameTooLong: '부제를 최대 30자 이하로 입력해주세요..',
  newappDescriptionEmpty: '앱 설명을 입력해주세요.',
  newappDescriptionTooLong: '앱 설명을 1000자 이하로 입력해주세요.',
  newappLanguageEmpty: '지원 언어를 선택해주세요.',
  newappKeywordEmpty: '키워드를 입력해주세요.',
  newappKeywordTooLong: '키워드를 10개 이하로 입력해주세요.',
  newappAppConflict: '이미 존재하는 앱은 등록할 수 없습니다.',

  modifyAppTitle: '앱 수정',
  modifyAppGuidelines: '앱 검토 지침',
  modifyAppUrl: '앱 URL',
  modifyAppProvider: '제공자:',
  modifyAppIcon: '아이콘',
  modifyAppIconSubtitle: '◦ 512x512px 권장',
  modifyAppName: '이름',
  modifyAppNameSubtitle: '◦ 최대 20자',
  modifyAppSubname: '부제',
  modifyAppSubnameSubtitle: '◦ 최대 30자',
  modifyAppDescription: '앱 설명',
  modifyAppDescriptionSubtitle: '◦ 최대 1000자',
  modifyAppDescriptionPlaceholder: '회원님의 앱에 대해 알려주세요.',
  modifyAppScreenshot: '앱 스크린샷',
  modifyAppScreenshotSubtitle: '◦ 최대 4장의 스크린샷 (1125x2436px 권장)',
  modifyAppCategory: '카테고리',
  modifyAppLanguage: '지원 언어',
  modifyAppKeyword: '키워드',
  modifyAppKeywordSubtitle: '◦ 쉼표(,)로 구분, 최대 10개',
  modifyAppComplete: '앱 제출',
  modifyAppUrlInvalid: '유효한 URL을 입력해주세요.',
  modifyAppIconEmpty: '아이콘 이미지를 선택해주세요.',
  modifyAppNameEmpty: '이름을 입력해주세요.',
  modifyAppNameTooLong: '이름을 20자 이하로 입력해주세요.',
  modifyAppSubnameEmpty: '부제를 입력해주세요.',
  modifyAppSubnameTooLong: '부제를 최대 30자 이하로 입력해주세요..',
  modifyAppDescriptionEmpty: '앱 설명을 입력해주세요.',
  modifyAppDescriptionTooLong: '앱 설명을 1000자 이하로 입력해주세요.',
  modifyAppLanguageEmpty: '지원 언어를 선택해주세요.',
  modifyAppKeywordEmpty: '키워드를 입력해주세요.',
  modifyAppKeywordTooLong: '키워드를 10개 이하로 입력해주세요.',
  modifyAppAppConflict: '이미 존재하는 앱은 등록할 수 없습니다.',

  guidelinesTitle: '앱 검토 지침',
  guidelines_asd: 'Aeida 앱 스토어는 Google과 Apple 앱 스토어의 과도한 심사 지침에 반대하는 개방형 플랫폼입니다. 우리는 모든 사용자가 자신의 필요와 관심사에 맞는 다양하고 흥미로운 앱을 자유롭게 다운로드할 수 있어야 한다고 믿습니다. 하지만 사람들을 기만하거나 부당한 이익을 취하는 앱, 어떤 형태로든 피해를 줄 수 있는 앱은 엄격히 금지됩니다. 우리의 목표는 앱 개발자들의 혁신을 장려하면서도 사용자를 안전하게 보호하는 것입니다. 이러한 목표를 위해 Aeida 앱 스토어가 제안하는 최소한의 앱 검토 지침에 대해 알아보세요.',
  guidelines_zcz: '1. Aeida 스토어 호환성',
  guidelines_afk: '• 모든 앱은 PWA를 지원해야 합니다.<br>• 여러분의 앱이 aeida.io 로부터 iframe 호스팅을 허용하는 경우, 해당 앱은 라이브러리에서 실행할 수 있는 앱으로 표시됩니다. 사용자는 여러분의 앱을 라이브러리에 추가하고 언제든 손쉽게 접근 및 실행할 수 있으며, 이는 사용자 경험에 긍정적인 영향을 줄 수 있습니다. 단, 이는 앱을 게시하는 데 필수 요소는 아닙니다.',
  guidelines_zcl: '2. 사용자 안정성',
  guidelines_mmm: '아래에 열거된 행위들은 사용자에게 잠재적 위험을 초래하거나 전반적인 앱 사용 경험을 심각하게 저해할 수 있습니다. 이러한 지침을 준수하지 않는 앱은 검토 과정에서 게시가 거부될 수 있습니다.',
  guidelines_all: '• 설명과 다른 앱을 제공함으로써 사용자를 기만하는 행위<br>• 무단으로 사용자 정보를 탈취하거나 사용자 기기에 악성코드를 설치하는 등 피싱 행위<br>• 별도의 앱 컨텐츠가 존재하지 않으며, 과도한 광고로 도배된 앱<br>• 이외에 명시되지 않은 합리적인 사회적 기대에 어긋나는 앱 또는 행위<br>',
  guidelines_alz: '3. 부적절한 컨텐츠',
  guidelines_lam: '아래에 열거된 컨텐츠들은 사용자에게 불쾌감을 주거나, 사회적인 혼란 또는 위협을 초래할 수 있습니다. 마찬가지로 앱에 이러한 컨텐츠가 존재하는 경우 검토 과정에서 게시가 거부될 수 있습니다.',
  guidelines_las: '• 미성년자와 관련된 부적절한 컨텐츠',
  guidelines_lzc: '4. 추가 및 면책 조항',
  guidelines_zlc: '• Aeida 스토어에 등록된 제공자명과 실제 서비스 제공자의 이름(조직명)이 상이한 경우, 법적 소유권 및 책임은 실제 서비스 제공자에게 있습니다. 이는 소유권 인증이 진행되지 않은 일부 앱의 제공자 표시가 Aeida.io 또는 당사로부터 위임된 대리인으로 표시된 경우에도 유효합니다.<br>• 소유권 인증은 해당 앱에서 더보기 - [◦ 소유권 인증]을 통해 진행할 수 있습니다.',

  appReportTitle: '앱 신고',
  appReportPlaceholder: '이 앱에 어떤 문제가 있는지 알려주세요.',
  appReportComplete: '제출',
  appReportReasonEmpty: '내용을 입력해주세요.',

  appAuthenticateTitle: '앱 소유자 인증',
  appAuthenticateComplete: '소유권 확인',
  appAuthenticateTextCheck: '소유권 확인 중',
  appAuthenticateTextTransfer: '소유권 확인 완료. 앱 소유권 이전 중 ...',
  appAuthenticateTextCheckFailed: '소유권 확인에 실패했습니다. 잠시 후 다시 시도해보세요.',
  appAuthenticateTextTransferComplete: '소유자 인증이 완료되었습니다.',

  inquiryTitle: '문의',
  inquiryEmail: '이메일',
  inquiryContents: '문의 내용',
  inquirySubmit: '제출',
  inquiryEmailEmpty: '이메일을 입력해주세요.',
  inquiryEmailInvalid: '올바른 이메일을 입력해주세요.',
  inquiryContentsEmpty: '문의 내용을 입력해주세요.',
  inquiryContentsTooLong: '문의 내용을 500자 이하로 입력해주세요.',

  ahmvTitle: '앱을 홈 화면에 추가 +',
  ahmvMobileChrome1: '1. <a class="highlight" href="{url}" target="_blank">{name} 앱</a>으로 이동하세요.',
  ahmvMobileChrome2: '2. 오른쪽 상단 <span class="highlight">︙ 더보기 아이콘</span>을 탭하세요.',
  ahmvMobileChrome3: '3. <span class="highlight">홈 화면에 추가</span>를 탭하세요.',
  ahmvMobileChrome4: '4. 팝업에서 <span class="highlight">설치</span>를 탭하세요.',

  ahmvMobileSafari1: '1. <a class="highlight" href="{url}" target="_blank">{name} 앱</a>으로 이동하세요.',
  ahmvMobileSafari2Part1: '2. 하단의 ',
  ahmvMobileSafari2Part2: '<span class="highlight" style="display: inline; vertical-align: middle;">공유 아이콘</span>을 탭하세요.',
  ahmvMobileSafari3: '3. <span class="highlight">홈 화면에 추가</span>를 탭하세요.',
  ahmvMobileSafari4: '4. 팝업에서 <span class="highlight">추가</span>를 탭하세요.',

  ahmvMobileOther1: '1. <a class="highlight" href="{url}" target="_blank">{name} 앱</a>으로 이동하세요.',
  ahmvMobileOther2: '2. <span class="theme--text font-weight-bold">아래의 설치 가이드</span>를 따라 앱을 설치하세요.',
  ahmvMobileOther3: '설치 가이드 보기',

  ahmvDesktopChrome1: '1. <a class="highlight" href="{url}" target="_blank">{name} 앱</a>으로 이동하세요.',
  ahmvDesktopChrome2Part1: '2. 상단 오른쪽 주소창의 ',
  ahmvDesktopChrome2Part2: '<span class="highlight" style="display: inline; vertical-align: middle;">다운로드 아이콘</span>을 탭하세요.',
  ahmvDesktopChrome3: '3. 팝업에서 <span class="highlight">설치</span>를 탭하세요.',

  ahmvDesktopSafari1: '1. <a class="highlight" href="{url}" target="_blank">{name} 앱</a>으로 이동하세요.',
  ahmvDesktopSafari2Part1: '2. 오른쪽 상단의 ',
  ahmvDesktopSafari2Part2: '<span class="highlight" style="display: inline; vertical-align: middle;">공유 아이콘</span>을 탭하세요.',
  ahmvDesktopSafari3: '3. <span class="highlight">Dock에 추가</span>를 탭하세요.',
  ahmvDesktopSafari4: '4. 팝업에서 <span class="highlight">추가</span>를 탭하세요.',

  ahmvDesktopOther1: '1. <a class="highlight" href="{url}" target="_blank">{name} 앱</a>으로 이동하세요.',
  ahmvDesktopOther2: '2. <span class="theme--text font-weight-bold">아래의 설치 가이드</span>를 따라 앱을 설치하세요.',
  ahmvDesktopOther3: '설치 가이드 보기',

  // pwa
  pwaTitle: 'Aeida 앱 설치',
  pwaSubTitle: '앱을 홈 화면에 추가하고<br>언제 어디서나 Aeida에 접속하세요.',
  pwaAndroidBrowser: '<a href="https://www.google.com/chrome/" target="_blank"><u>Chrome</u></a>에서 이 단계를 따르세요.',
  pwaAndroid1: '1. <span class="highlight">더보기</span> 아이콘을 탭하세요.',
  pwaAndroid2: '2. <span class="highlight">홈 화면에 추가</span> 를 탭하세요.',
  pwaAndroid3: '3. 팝업에서 <span class="highlight">설치</span> 를 탭하세요.',
  pwaAndroid4: '4. 팝업에서 <span class="highlight">설치</span> 를 탭하세요.',
  pwaIPhone1: '1. <span class="highlight">공유</span> 아이콘을 탭하세요.',
  pwaIPhone2: '2.<span class="highlight">홈 화면에 추가</span> 를 탭하세요.',
  pwaIPhone3: '3. 오른쪽 상단 <span class="highlight">추가</span> 를 탭하세요.',
  pwaDesktopBrowser: '<a href="https://www.google.com/chrome/" target="_blank"><u>Chrome</u></a> 또는 <a href="https://www.microsoft.com/edge" target="_blank"><u>Edge</u></a>에서 이 단계를 따르세요.',
  pwaDesktop1: '상단 주소창의 설치 아이콘 및 설치 버튼을 탭하거나, 아래의 설치 버튼을 탭하세요. 시크릿 모드의 경우 설치 아이콘이 표시되지 않을 수 있습니다.',
  pwaOtherBrowser: '<a style="color:var(--black-1) !important;"><u>다른 브라우저를 사용하시나요? 더 많은 설치 가이드가 준비되어 있어요.</u></a>',
  pwaInstall: '설치',
  pwaCannotInstall: '이미 설치된 앱이거나, 일시적으로 설치할 수 없습니다.',

  pwaGuideTitle: 'Aeida 앱 설치하기',
  // pwa popup
  pwaMobileTitle: 'Mobile',
  pwaSamsungAndroid: '· 삼성 인터넷 on Android',
  pwaSamsungAndroid1: '1. <span class="highlight">︙ 더보기</span> 아이콘을 탭하세요.',
  pwaSamsungAndroid2: '2. <span class="highlight">현재 페이지 추가</span>를 탭하세요.',
  pwaSamsungAndroid3: '3. <span class="highlight">홈 화면</span>를 탭하세요.',
  pwaSamsungAndroid4: '4. <span class="highlight">추가</span> 버튼을 탭하세요.',
  pwaFirefoxAndroid: '· Firefox on Android',
  pwaFirefoxAndroid1: '1. <span class="highlight">︙ 더보기</span> 아이콘을 탭하세요.',
  pwaFirefoxAndroid2: '2. <span class="highlight">홈 화면에 추가</span>를 탭하세요.',
  pwaFirefoxAndroid3: '3. <span class="highlight">추가</span> 버튼을 탭하세요.',
  pwaEdgeAndroid: '· Edge on Android',
  pwaEdgeAndroid1: '1. <span class="highlight">︙ 더보기</span> 아이콘을 탭하세요.',
  pwaEdgeAndroid2: '2. <span class="highlight">휴대폰에 추가</span>를 탭하세요.',
  pwaEdgeAndroid3: '3. <span class="highlight">설치</span> 버튼을 탭하세요.',
  pwaFirefoxIOS: '· Firefox on iOS',
  pwaFirefoxIOS1: '1. <span class="highlight">공유</span> 아이콘을 탭하세요.',
  pwaFirefoxIOS2: '2. <span class="highlight">홈 화면에 추가</span>를 탭하세요.',
  pwaFirefoxIOS3: '3. <span class="highlight">추가</span> 버튼을 탭하세요.',
  pwaEdgeIOS: '· Edge on iOS',
  pwaEdgeIOS1: '1. <span class="highlight">공유</span> 아이콘을 탭하세요.',
  pwaEdgeIOS2: '2. <span class="highlight">홈 화면에 추가</span>를 탭하세요.',
  pwaEdgeIOS3: '3. <span class="highlight">추가</span> 버튼을 탭하세요.',
  pwaDesktopTitle: 'Desktop',
  pwaEdgeDesktop: '· Edge on Windows and Mac',
  pwaEdgeDesktop1: '1. <span class="highlight">앱</span> - <span class="highlight">이 사이트를 앱으로 설치</span> 를 탭하세요.',
  pwaEdgeDesktop2: '2. <span class="highlight">설치</span> 버튼을 탭하세요.',
  pwaSafariMacOS: '· Safari on Mac',
  pwaSafariMacOS1: '1. <span class="highlight">공유</span> 아이콘을 탭하세요.',
  pwaSafariMacOS2: '2. <span class="highlight">Dock에 추가</span> 를 탭하세요.',
  pwaSafariMacOS3: '3. <span class="highlight">추가</span> 버튼을 탭하세요.',

  libraryAdded: '라이브러리에 추가되었습니다.',
  libraryAlreadyExist: '이미 라이브러리에 추가된 앱입니다.',
  
  hostedTitle: '◦ hosted by Aeida',

  myappTitle: '나의 앱',
  
  appApproved: '게시됨',
  appPending: '검토 중',
  appDenied: '거부됨',
  appModify: '앱 수정',
  appDelete: '앱 삭제',

  categoryEmpty: '카테고리 선택',
  categoryDating: '데이팅',
  categoryGame: '게임',
  categoryEntertainment: '엔터테인먼트',
  categoryShopping: '쇼핑',
  categoryTravel: '여행',
  categoryEducation: '교육',
  categoryNews: '뉴스',
  categoryMedia: '미디어',
  categorySocial: '소셜',
  categoryFinancial: '금융',

  billingResponse3002: '카드 결제에 실패했습니다.',
  billingResponse3011: '카드 번호가 올바르지 않습니다.',
  billingResponse3021: '유효기간이 올바르지 않습니다.',
  billingResponse3054: '지원하지 않는 카드입니다. (국외카드)',
  billingResponse3098: '지원하지 않는 카드입니다. (국외카드)',
  billingResponse9998: '카드 정보가 올바르지 않습니다.',

  languageEmpty: '언어 선택',
  languageKorean: '한국어',
  languageEnglish: '영어',
  languageJapanese: '일본어',
  languageGlobal: '글로벌 (다국어)',

  footerCustomerService: '고객센터: aeida.store@gmail.com',
  footerTerms: 'Aeida 스토어 약관',

  alertBack: '정말로 페이지를 떠나시겠습니까?',

  back: '뒤로 가기',

  serverError: '잠시 후 다시 시도해주세요.',

  // meta
  meta: {
    "title": "Aeida 앱 스토어",
    "description": "다른 앱스토어에서는 찾을 수 없는 특별한 앱들을 찾고, 설치하고, 즐기세요. 한계 없는 무한한 앱스토어 Aeida.",
    "keywords": "앱스토어, 앱, 플레이스토어, 안드로이드, 개발자, android, ios, app, appstore, apple appstore, google playstore, apps,developer",
  },
  
};